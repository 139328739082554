import React, { useLayoutEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { InputProps } from "./Form";

const ControlledTextarea: React.FC = ({
  label,
  value,
  onChange,
  disabled,
  maxLength,
  ...remainingProps
}) => {
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  useLayoutEffect(() => {
    const textarea = ref.current;
    if (textarea) textarea.setSelectionRange(cursor, cursor);
    // console.log('useLayoutEffect', { textarea, cursor, value })
  }, [ref, cursor, value]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e);
  };

  return (
    <textarea
      ref={ref}
      rows={4}
      css={[
        tw`shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md`,
      ]}
      value={value}
      onChange={handleChange}
      maxLength={maxLength}
      disabled={disabled}
      {...remainingProps}
    />
  );
};

export default ControlledTextarea;
