import React, { useState } from "react";
import tw from "twin.macro";
import { Button } from "../Button";
import { PreviewGallery } from "../PreviewGallery";
import { Modal } from "../Modal";

const PreviewGalleryModal = ({ themeID, initialCollection, onSelect }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      trigger={
        <Button primary>
          Preview
        </Button>
      }
      css={tw`overflow-y-scroll`}
    >
      <PreviewGallery
        themeID={themeID}
        initialCollection={initialCollection}
        selectImage={(selectedImage) => {
          onSelect(selectedImage);
          setOpen(false);
        }}
      />
    </Modal>
  );
};

export default PreviewGalleryModal;
