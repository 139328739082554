import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import EventsClient from "../../../clients/Events";
import OrganizationsClient from "../../../clients/Organizations";
import { Organization } from "../../../clients/Organizations.d";
import { NotificationsContext } from "../../../context/Notifications";
import { addNotification } from "../../../context/Notifications/actions";
import Container from "../../Container/Container";
import { Link } from "../../Link/Link";
import PageHeading from "../../PageHeading/PageHeading";
import { Table, TD, TH, THead, TR } from "../../Table/TableElements";
import { Event } from "../../../clients/Events.d"

const OrganizationsListPage = () => {
  const [data, setData] = useState(null);
  const [events, setEvents] = useState(null);
  const [notifications, dispatch] = useContext(NotificationsContext)

  useEffect(() => {
    const getOrganizations = async () => {
      try {
        const organizations = await new OrganizationsClient().get();
        setData(organizations)
      } catch(err) { 
        dispatch(addNotification({title: "Error", description: err?.message, type: "error"}))
      }
    };
    const getEvents = async () => {
      try {
        const events = await new EventsClient().get()
        setEvents(events)
      } catch(err) {
        dispatch(addNotification({title: "Error", description: err?.message, type: "error"}))
      }
    }

    getOrganizations();
    getEvents()
  }, []);

  return (
    <Container>
      <PageHeading
        buttons={[
          {
            label: "Add New",
            link: "/organizations/new",
            variations: { primary: true },
          },
        ]}
      >
        Organizations
      </PageHeading>
      <Table>
        <THead>
          <TR>
            <TH>Organization</TH>
            <TH>Status</TH>
            <TH>Created</TH>
            <TH>PIN</TH>
            <TH>Events</TH>
            <TH></TH>
          </TR>
        </THead>
        {data &&
          data.map((organization: Organization) => {
            const date = new Date(organization.creationDate.replace('T', ' ')).toLocaleDateString()
            return (
              <TR>
                <TD css={[tw`font-medium`]}>{organization.orgName}</TD>
                <TD>{organization.statusCode}</TD>
                <TD>{date}</TD>
                <TD>{organization.pin}</TD>
                <TD css={tw`whitespace-normal max-w-3xl`}>
                  {events && ( 
                    events.map((event: Event) => {
                      if(event.organizationID === organization.organizationID) {
                        return (
                          <Link 
                            to={`/events/${event.eventID}`}
                            css={tw`inline-block w-full`}
                          >
                            {event.title}
                          </Link>
                        )
                      }
                    })
                  )}
                </TD>
                <TD>
                  <Link to={`/organizations/${organization.organizationID}`}>
                    Edit<span css={[tw`sr-only`]}>, {organization.orgName}</span>
                  </Link>
                </TD>
              </TR>
            )
          })}
      </Table>
    </Container>
  );
};

export default OrganizationsListPage;
