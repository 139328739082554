import { MenuAlt2Icon } from "@heroicons/react/outline";
import { navigate } from "gatsby";
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import { UserContext } from "../../context/User/UserContext";
import { GlobalStyles } from "../GlobalStyles";
import { Navbar } from "../Navbar";
import NotificationsList from "../Notification/NotificationsList";

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Navbar open={sidebarOpen} setOpen={setSidebarOpen} />
      <NotificationsList />
      <div css={[tw`md:pl-64`]}>
        {/* <div css={[tw`max-w-7xl mx-auto flex flex-col md:px-8 xl:px-0`]}> */}
        <div css={[tw`max-w-5xl mx-auto flex flex-col md:px-8 xl:px-0`]}>
          <div
            css={[
              tw`sticky top-0 z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 flex md:hidden`,
            ]}
          >
            <button
              type="button"
              css={[
                tw`border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 `,
              ]}
              onClick={() => setSidebarOpen(true)}
            >
              <span css={[tw`sr-only`]}>Open sidebar</span>
              <MenuAlt2Icon css={[tw`h-6 w-6`]} aria-hidden="true" />
            </button>
          </div>

          <main css={[tw`flex-1`]}>{children}</main>
        </div>
      </div>
    </>
  );
};

export default Layout;
