import React from "react";
import tw from "twin.macro";
import { RadioGroupProps } from "./Form.d";

const RadioGroup: React.FC<RadioGroupProps> = ({
  selected,
  options,
  onChange,
  ...remainingProps
}) => {
  return (
    <fieldset css={[tw`mt-4`]} {...remainingProps}>
      <legend css={[tw`sr-only`]}>Notification method</legend>
      <div
        css={[tw`space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10`]}
      >
        {options.map((option) => (
          <div key={option.value} css={[tw`flex items-center`]}>
            <input
              id={option.value}
              name="notification-method"
              type="radio"
              defaultChecked={option.value === selected}
              css={[
                tw`focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300`,
              ]}
              onChange={(e) => onChange(option.value)}
              checked={option.value === selected}
            />
            <label
              htmlFor={option.value}
              css={[tw`ml-3 block text-sm font-medium text-gray-700`]}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};
export default RadioGroup;
