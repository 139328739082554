import { HTTP_METHOD, User } from "../global.d"
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";
import { AuthResponse } from "./Auth";
import { Event } from "./Events.d";

class UserClient extends APIBaseClient {
    constructor() {
        super()
        this.baseEndpoint = 'auth'
    }
    private loggedInUser = new AuthClient().getUser()
    private token = this.loggedInUser.tokenPayload?.access_token

    public get = async () => {
        const res = await this.call("users", HTTP_METHOD.GET, {}, this.token)
        return res
    }

    public getAdmins = async () => {
        const res = await this.call("users?Role=Admin", HTTP_METHOD.GET, {}, this.token)
        return res
    }

    public getStandards = async () => {
        const res = await this.call("users?Role=Standard", HTTP_METHOD.GET, {}, this.token)
        return res
    }

    public find = async (userID: string) => {
        const res = await this.call(`users/${userID}`, HTTP_METHOD.GET, {}, this.token)
        return res
    }

    public getEvents = async (userID: string) => {
        const res = await this.call(`event-admins/users/${userID}`, HTTP_METHOD.GET, {}, this.token)
        return res
    }

    public getNewEvents = async (userID: string) => {
        const res = await this.call(`event-admins/users/${userID}/new`, HTTP_METHOD.GET, {}, this.token)
        return res
    }

    public create = async (user: User) => {
        const res = await this.call("register", HTTP_METHOD.POST, user, this.token)
        return res
    }

    public createAdmin = async (user: User): AuthResponse => {
        const res = await this.call("register-admin", HTTP_METHOD.POST, user, this.token)
        return res
    }

    public delete = async (userID: string) => {
        const res = await this.call(`users/${userID}`, HTTP_METHOD.DELETE, {}, this.token)
        return res
    }

    public update = async (user: User) => {
        const res = await this.call(`users/${user.userID}`, HTTP_METHOD.PUT, user, this.token)
        return res
    }

    public updateEvemtAdmin = async (userID: string, events: Event[]) => {
        const res = await this.call(`event-admins/users/${userID}`, HTTP_METHOD.PUT, events, this.token)
        return res
    }

    public sendPasswordReset = async (email: string) => {
        const res = await this.call(`send-password-reset-email?Email=${email}`, HTTP_METHOD.GET, {}, this.token)
        return res
    }
}

export default UserClient