import { MenuAlt2Icon } from "@heroicons/react/outline";
import { navigate } from "gatsby";
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import { UserContext } from "../../context/User/UserContext";
import { GlobalStyles } from "../GlobalStyles";
import { Navbar } from "../Navbar";

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <GlobalStyles />
      {children}
    </>
  );
};

export default Layout;
