import { CogIcon } from "@heroicons/react/solid";
import React, { useEffect, useState, useContext } from "react";
import tw from "twin.macro";
import EventsClient from "../../../clients/Events";
import { NotificationsContext } from "../../../context/Notifications";
import { addNotification } from "../../../context/Notifications/actions";
import Container from "../../Container/Container";
import PageHeading from "../../PageHeading/PageHeading";
import { EventsEditPageProps } from "./Event.d";
import OrganizationsClient from "../../../clients/Organizations";
import Select from "../../Form/Select";
import { Input } from "../../Form";
import EventNewClient from "../../../clients/EventNew";
import DatePicker from "../../Form/DatePicker";
import TemplateClient from "../../../clients/Templates";
import { navigate } from "gatsby";

const EventsNewPage: React.FC<EventsEditPageProps> = () => {
  const [Notifications, dispatch] = useContext(NotificationsContext);
  const [organizations, setOrganizations] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [form, setForm] = useState({
    organizationID: "",
    title: "",
    statusCode: "Active",
    pin: "",
    templateEventID: templates?.filter(
      (template: { title: string }) => template.title == "DEFAULT EVENT"
    ).eventID,
  });
  
  // Fetch Organizations and Templates
  useEffect(() => {
    const getOrganizations = async () => {
      const organizations = await new OrganizationsClient().get();
      setOrganizations(organizations);
    };

    const getTemplates = async () => {
      const templates = await new TemplateClient().getTemplates();
      setTemplates(templates);
    };

    getOrganizations();
    getTemplates();
  }, []);

  // Update Form Values
  const updateFormValue = (e: any) => {
    if (e.target.name == "organizationID") {
      const obj = JSON.parse(e.target.value);
      setForm({
        ...form,
        organizationID: obj.organizationID,
      });
    } else if (e.target.name == "templates") {
      const obj = JSON.parse(e.target.value);
      setForm({
        ...form,
        templateEventID: obj.eventID,
      });
    } else if (e.target.name == "pin") {
      const reg = new RegExp('^[0-9]+$');
      const value = e.target.value;
        // console.log(value, Number.parseInt(value), reg.test(value), value.length)
        if (value === '' || (value.length <= 4 && reg.test(value) && (Number.parseInt(value) >= 0 && Number.parseInt(value) <= 9999))) {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          }); 
        }
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const submit = async () => {
    try {
      const res = await new EventNewClient().createEvent(form);
      dispatch(
        addNotification({
          title: "Success",
          description: "You have created a new event.",
          type: "success",
        })
      );
      navigate("/events")
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occured.",
            type: "error",
          })
        );
      }
    }
  };

  return (
    <Container>
      <PageHeading
        buttons={[
          { label: "Cancel", variations: { light: true }, link: `/events` },
          { label: "Save", variations: { primary: true }, onClick: submit },
        ]}>
        Create New Event
      </PageHeading>
      <div>
        <div css={[tw`hidden sm:block`]}>
          <div css={[tw`border-b border-gray-200`]}>
            <div
              css={[tw`text-primary-600 inline-flex items-center py-4 px-1 font-medium text-sm`]}>
              <CogIcon css={[tw`text-primary-500 -ml-0.5 mr-2 h-5 w-5`]} aria-hidden="true" />
              <span>Details</span>
            </div>
          </div>
        </div>
      </div>
      <div css={[tw`mt-8 grid gap-4 grid-cols-2 `]}>
        <Input
          label="Event Name"
          name="title"
          placeholder="Create Name"
          onChange={updateFormValue}
          value={form.title}
        />
        <Select label="Organization" name="organizationID" onChange={updateFormValue} defaultValue=''>
          <option value="" disabled>
            Select Organization
          </option>
          {organizations &&
            organizations.map((organization: any, i: number) => (
              <option key={organization.orgName + i} value={JSON.stringify(organization, null, 2)}>{organization.orgName}</option>
            ))}
        </Select>
        <Select label="Copy Event Configuration From" name="templates" onChange={updateFormValue} defaultValue=''>
          <option value="" disabled>
            Select Template
          </option>
          {templates &&
            templates.map((template: any, i: number) => (
              <option key={template.title + i} value={JSON.stringify(template, null, 2)}>{template.title}</option>
            ))}
        </Select>
        <Select label="Status" name="statusCode" value={form.statusCode} onChange={updateFormValue}>
          <option value={"Active"}>{"Active"}</option>
          <option value={"Inactive"}>{"Inactive"}</option>
        </Select>
        <Input label="Kiosk Pin" name="pin" placeholder="Create Pin" onChange={updateFormValue} value={form.pin} />
      </div>
    </Container>
  );
};

export default EventsNewPage;
