import React from "react";

const Station5Details = ({ data }) => {
  return (
    <div>
      <p>Station 5 details</p>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
  );
};

export default Station5Details;
