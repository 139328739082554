import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";
import { Organization } from './Organizations.d'

class OrganizationsClient extends APIBaseClient {
    constructor() {
        super()
        this.baseEndpoint = 'organizations'
    }
    private loggedInUser = new AuthClient().getUser()
    private token = this.loggedInUser.tokenPayload?.access_token


    public get = async (): Promise<Organization[]> => {
        const res = await this.call('?IsAdmin=true', HTTP_METHOD.GET)
        return await res
    }

    public getOne = async (id: string): Promise<Organization> => {
        const res = await this.call(id, HTTP_METHOD.GET)
        return await res
    }

    public create = async (newOrganization: Organization): Promise<Organization> => {
        const res = await this.call('', HTTP_METHOD.POST, newOrganization, this.token)
        return await res
    }

    public update = async (updatedOrganization: Organization): Promise<Organization> => {
        const res = await this.call(updatedOrganization.organizationID, HTTP_METHOD.PUT, updatedOrganization, this.token)
        return await res
    }
}

export default OrganizationsClient