import { PlayIcon, VideoCameraIcon, XIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { Button } from "../Button";
import { MediaGalleryModal } from "../MediaGallery";

const ImageSelector = ({ eventConfigID, initialCollection, label, disabled, value, onUpdate, ...remainingProps }) => {
  const [file, setFile] = useState(value);
  const [filePath, setFilePath] = useState('');
  const [fileType, setFileType] = useState('');

  // console.log('ImageSelector Props', label, eventConfigID, initialCollection)

  useEffect(() => {
    if(onUpdate) {
      onUpdate(file)
    }

    if (!file) return;
    const parts = file.split(".");
    setFileType(parts[parts.length - 1]);
    setFilePath(`${process.env.GATSBY_AUTH_API_ROOT_URL}/Assets/${file}`);
  }, [file]);

  return (
    <div
      css={[disabled && tw`pointer-events-none opacity-50`]}
      {...remainingProps}
    >
      <label
        htmlFor={label}
        css={[tw`block text-sm font-medium text-gray-700`]}
      >
        {label}
      </label>
      <div css={[tw`mt-1`]}>
        {file && filePath ? (
          <div
            css={[
              tw`flex w-full pr-3 border border-transparent placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm`,
            ]}
          >
            <a
              href={filePath}
              target="_blank"
              css={[
                tw`bg-gray-300 block mr-4 rounded overflow-hidden flex-none`,
              ]}
            >
              {fileType === "mov" ? (
                <VideoCameraIcon css={[tw`h-6 m-1.5 text-gray-600`]} />
              ) : (
                <img src={filePath} css={[tw`w-9 h-9 object-contain`]} />
              )}
            </a>
            <span css={[tw`py-2 text-gray-800 block truncate flex-initial`]}>
              {file}
            </span>
            <button
              css={[tw`p-2 hover:text-primary-500`]}
              onClick={() => setFile('')}
            >
              <XIcon css={[tw`h-4`]} />
            </button>
          </div>
        ) : (
          <MediaGalleryModal eventConfigID={eventConfigID} initialCollection={initialCollection} onSelect={(file) => setFile(file.fileName)} />
        )}
      </div>
    </div>
  );
};

export default ImageSelector;
