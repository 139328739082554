import React from "react";
import tw from "twin.macro";
import { Tab, TabsProps } from "./Tabs.d";

const Tabs: React.FC<TabsProps> = ({ tabs, onChange }) => {
  return (
    <div>
      <div css={[tw`sm:hidden`]}>
        <label htmlFor="tabs" css={[tw`sr-only`]}>
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          css={[
            tw`block w-full focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md`,
          ]}
          onChange={(e) => onChange(e.target.value)}
        >
          {tabs.map((tab: Tab, i: number) => (
            <option key={`tab-${i}`} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div css={[tw`hidden sm:block`]}>
        <div css={[tw`border-b border-gray-200`]}>
          <nav css={[tw`-mb-px flex space-x-8 overflow-x-scroll`]} aria-label="Tabs">
            {tabs.map((tab: Tab) => (
              <button
                key={tab.name}
                onClick={() => onChange(tab.href)}
                className="group"
                css={[
                  tab.current
                    ? tw`border-primary-500 text-primary-600`
                    : tw`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300`,
                  tw` inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm`,
                ]}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.icon && (
                  <tab.icon
                    css={[
                      tab.current
                        ? tw`text-primary-500`
                        : tw`text-gray-400 group-hover:text-gray-500`,
                      tw`-ml-0.5 mr-2 h-5 w-5`,
                    ]}
                    aria-hidden="true"
                  />
                )}
                <span>{tab.name}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
