import React from "react";
import { Router } from "@reach/router";
import { Layout } from "../components/Layout";
import  PrivateRoute  from "../components/PrivateRoute";
import LoginPage from "../components/pages/Login";
import NotFoundPage from "../components/pages/404";
import DashboardPage from "../components/pages/Dashboard";
import EventsListPage from "../components/pages/Events/list";
import EventsEditPage from "../components/pages/Events/edit/";
import ThemesListPage from "../components/pages/Themes/list";
import ThemesEditPage from "../components/pages/Themes/edit";
import OrganizationsListPage from "../components/pages/Organizations/list";
import OrganizationsEditPage from "../components/pages/Organizations/edit";
import UsersListPage from "../components/pages/Users/list";
import UsersEditPage from "../components/pages/Users/edit";
import UsersNewPage from "../components/pages/Users/new";
import Profile from "../components/pages/Profile";
import MediaGalleryPage from "../components/pages/Media";
import QuestionnaireListPage from "../components/pages/Questionnaire/list";
import EventsNewPage from "../components/pages/Events/new";

const App = () => {
  return (
    <Layout>
      <Router basepath="/">
        <PrivateRoute path="/" component={DashboardPage} />
        <PrivateRoute path="/events" component={EventsListPage} />
        <PrivateRoute path="/events/:eventID" component={EventsEditPage} />
        <PrivateRoute path="/events/new" component={EventsNewPage} />
        <PrivateRoute path="/organizations" component={OrganizationsListPage} />
        <PrivateRoute path="/questionnaire" component={QuestionnaireListPage} />
        <PrivateRoute
          path="/organizations/:organizationID"
          component={OrganizationsEditPage}
        />

        <PrivateRoute path="/organizations/new" component={OrganizationsEditPage} />
        <PrivateRoute path="/themes" component={ThemesListPage} />
        <PrivateRoute path="/themes/:themeID" component={ThemesEditPage} />
        {/* <PrivateRoute path="/themes/new" component={ThemesNewPage} /> */}
        <PrivateRoute path="/users" component={UsersListPage} />
        <PrivateRoute path="/users/:userID" component={UsersEditPage} />
        <PrivateRoute path="/users/new" component={UsersNewPage} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/media" component={MediaGalleryPage} />
        <PrivateRoute component={NotFoundPage} default />
        <LoginPage path="/login" />
      </Router>
    </Layout>
  );
};
export default App;
