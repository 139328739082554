import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import { Asset } from "./Asset.d";
import AuthClient from "./Auth";

class AssetClient extends APIBaseClient {
  constructor() {
    super();
    this.baseEndpoint = "event-configs";
  }
  private loggedInUser = new AuthClient().getUser();
  private token = this.loggedInUser?.tokenPayload?.access_token;

  public get = async (
    eventConfigID: string,
    collection?: string,
    statusCode?: string
  ): Promise<Asset[]> => {
    let url = `${eventConfigID}/assets`;
    if (collection && !statusCode) {
      url = `${url}?Collection=${collection}`;
    }
    if (!collection && statusCode) {
      url = `${url}?StatusCode=${statusCode}`;
    }
    if (collection && statusCode) {
      url = `${url}?Collection=${collection}&StatusCode=${statusCode}`;
    }
    const res = await this.call(url, HTTP_METHOD.GET);
    return await res;
  };

  public getCollections = async (eventConfigID: string): Promise<string[]> => {
    const res = await this.call(
      `${eventConfigID}/asset-collections`,
      HTTP_METHOD.GET
    );
    return await res;
  };

  public create = async (
    eventConfigID: string,
    file: Blob,
    collection: string
  ): Promise<Asset[]> => {
    const formData = new FormData();

    formData.append("files", file);

    const res = await fetch(
      `${this.rootUrl}/${this.baseEndpoint}/${eventConfigID}/assets?Collection=${collection}`,
      {
        method: HTTP_METHOD.POST,
        body: formData,
        headers: {
            "Authorization": "Bearer " + this.token
        },
      }
    );
    return res.json();
  };

  public uploadChunk = async (
    eventConfigID: string,
    chunk: Blob,
    collection: string,
    fileName: string
  ): Promise<Asset[]> => {
    const formData = new FormData();

    formData.append("files", chunk, fileName);

    const res = await fetch(
      `${this.rootUrl}/${this.baseEndpoint}/${eventConfigID}/large-assets?Collection=${collection}`,
      {
        method: HTTP_METHOD.POST,
        body: formData,
        headers: {
            "Authorization": "Bearer " + this.token
        },
      }
    );
    return res.json();
  };

  public cancelUpload = async (
    eventConfigID: string,
    chunk: Blob,
    collection: string,
    fileName: string
  ): Promise<Asset[]> => {
    const formData = new FormData();

    formData.append("files", chunk, fileName);

    const res = await fetch(
      `${this.rootUrl}/${this.baseEndpoint}/${eventConfigID}/large-assets?Collection=${collection}&Cancel=true`,
      {
        method: HTTP_METHOD.POST,
        body: formData,
        headers: {
            "Authorization": "Bearer " + this.token
        },
      }
    );
    return res.json();
  };

  public update = async (eventConfigID: string, assets: Array<Asset>) => {
    return await this.call(`${eventConfigID}/assets`, HTTP_METHOD.PUT, assets);
  };

  public prune = async (
    eventConfigID: string,
    deleteInactive: boolean = false
  ) => {
    return await this.call(
      `${eventConfigID}/assets/prune?DeleteInactive=${deleteInactive}`,
      HTTP_METHOD.GET
    );
  };

  public delete = async (eventConfigID: string, assets: Array<Asset>) => {
    return await this.call(
      `${eventConfigID}/assets`,
      HTTP_METHOD.DELETE,
      assets
    );
  };
}

export default AssetClient;
