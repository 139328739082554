import { PencilIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import UserClient from "../../../clients/User";
import { NotificationsContext } from "../../../context/Notifications";
import { addNotification } from "../../../context/Notifications/actions";
import { User } from "../../../global";
import { isBrowser } from "../../../helpers";
import { Button } from "../../Button";
import { ButtonLink } from "../../Button/Button";
import Container from "../../Container/Container";
import { Link } from "../../Link/Link";
import PageHeading from "../../PageHeading/PageHeading";
import { Table, TD, TH, THead, TR } from "../../Table/TableElements";

const UsersListPage = () => {
  const client = new UserClient();
  const [data, setData] = useState(null);
  const [notifications, dispatch] = useContext(NotificationsContext);

  const handleDelete = async (id: string) => {
    try {
      const res = client.delete(id);
      dispatch(
        addNotification({
          title: "Success",
          description: "User has been deleted.",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occurred.",
            type: "error",
          })
        );
      }
    }
    window.location.reload();
  };

  const handleResetPassword = async (email: string) => {
    const formattedEmail = encodeURIComponent(email);
    try {
      const res = client.sendPasswordReset(formattedEmail);
      dispatch(
        addNotification({
          title: "Success",
          description: "An email has been sent prompting user to reset password.",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occurred.",
            type: "error",
          })
        );
      }
    }
  };

  const handleRoleChange = async (user: User) => {
    try {
      if (user.role === "Admin") {
        const updateRole = await client.update({ ...user, role: "Standard" });
      }
      if (user.role === "Standard") {
        const updateRole = await client.update({ ...user, role: "Admin" });
      }

      dispatch(
        addNotification({
          title: "Success",
          description: "User role was updated.",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occurred.",
            type: "error",
          })
        );
      }
    }
    window.location.reload();
  };

  useEffect(() => {
    if (!isBrowser) return;
    const getUsers = async () => {
      try {
        const data = await client.get();
        const admins = data.filter((u: User) => u.role.includes('Admin'));
        setData(admins);
      } catch (err) {
        if (err?.message && err?.message != "Response not okay.") {
          dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
        } else {
          dispatch(
            addNotification({
              title: "Error",
              description: "An unexpected error has occurred.",
              type: "error",
            })
          );
        }
      }
    };

    getUsers();
  }, []);

  return (
    <Container>
      <PageHeading
        buttons={[
          {
            label: "Add Admin",
            variations: { primary: true },
            link: "/users/new",
          }
        ]}
      >
        Users
      </PageHeading>

      <Table>
        <THead>
          <TR>
            <TH>First Name</TH>
            <TH>Last Name</TH>
            <TH>Role</TH>
            <TH>Email</TH>
            <TH>Status</TH>
            <TH></TH>
          </TR>
        </THead>
        {data &&
          data.map((user: User) => (
            <TR>
              <TD>{user.firstName}</TD>
              <TD>{user.lastName}</TD>
              {user.role === 'Admin' && <TD>Super Admin</TD>}
              {user.role === 'Event Admin' && <TD>Event Admin</TD>}
              {user.role === 'Standard' && <TD>Standard Visitor</TD>}
              <TD>
                <Link to={`mailto:${user.email}`}>{user.email}</Link>
              </TD>
              <TD>{user.emailStatus}</TD>
              <TD>
                <Link to={`/users/${user.userID}`}>Edit</Link>
              </TD>
              {/* <TD>
                <button
                  css={tw`text-primary-600 hover:text-primary-700`}
                  onClick={() => handleResetPassword(user.email)}>
                  Reset Password
                </button>
              </TD>
              <TD>
                <button
                  css={
                    user.role == "Admin"
                      ? tw`text-primary-600 hover:text-primary-700`
                      : user.role == "Standard"
                      ? tw`text-green-600 hover:text-green-700`
                      : null
                  }
                  onClick={() => handleRoleChange(user)}>
                  {user.role == "Standard"
                    ? "Make Admin"
                    : user.role == "Admin"
                    ? "Remove Admin"
                    : ""}
                </button>
              </TD>
              <TD>
                <button
                  css={tw`text-primary-600 hover:text-primary-700`}
                  onClick={() => handleDelete(user.userID)}>
                  Delete
                </button>
              </TD> */}
            </TR>
          ))}
      </Table>
    </Container>
  );
};

export default UsersListPage;
