import React, { useContext } from 'react'
import tw from 'twin.macro'
import Notification from './Notification'
import { NotificationsContext } from '../../context/Notifications'

const NotificationsList: React.FC = () => {
    const [notifications] = useContext(NotificationsContext)

    return (
        <div css={tw`fixed right-0 top-16 z-50 sm:right-4 md:top-2`}>
            {notifications.map((notification, i: any) => (
                <Notification key={notification + i} {...notification} />
            ))}
        </div>
    )
}

export default NotificationsList
