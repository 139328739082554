import { CalendarIcon } from "@heroicons/react/solid";
import tw from "twin.macro";
import { DatePickerProps } from "./Form.d";

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  ...remainingProps
}) => {
  return (
    <div>
      <label
        htmlFor={label.toLowerCase().replace(" ", "")}
        css={[tw`block text-sm font-medium text-gray-700`]}
      >
        {label}
      </label>
      <div css={[tw`mt-1 relative rounded-md shadow-sm`]}>
        <input
          type="date"
          name={label.toLowerCase().replace(" ", "")}
          id={label.toLowerCase().replace(" ", "")}
          css={[
            tw`focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md`,
          ]}
          {...remainingProps}
        />
      </div>
    </div>
  );
};

export default DatePicker;
