import React, {useEffect, useState} from 'react'
import tw from 'twin.macro';
import Image from '../Image/Image'

const KioskAdminCard = ({icon, iconLabel, selected}) => {

    return (
        <div 
            css={[tw`text-center px-4 pt-10 pb-12 bg-gradient-to-b from-[#E5554F] to-[#B32924]`,
                selected && tw`from-[#34657F] to-[#34657F]`
            ]}
        >
            <h2 css={tw`text-white mb-12 text-4xl font-semibold tracking-wide`}>{iconLabel}</h2>
            <div 
                css={[tw`mx-auto p-8 inline-block rounded-full bg-[#823333] border-4 border-[#A92822] drop-shadow-md`,
                    selected && tw`bg-[#1F465F] border-[#305C72]`,
                ]}
            >
                <Image 
                    src={`${process.env.GATSBY_AUTH_API_ROOT_URL}/Assets/${icon}`} 
                    alt={iconLabel} 
                    css={tw`w-20 h-20 object-contain mx-auto`} 
                />
            </div>
        </div>
    )
}

export default KioskAdminCard
