import tw, { TwStyle } from "twin.macro";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import Transition from "../Transition/Transition";
import { XIcon } from "@heroicons/react/solid";

/**
 * HeadlessUI "Dialog (Modal)"
 * Customized for twin.macro + typescript
 * https://headlessui.dev/react/dialog
 */

type ContentProps = {
  title: string;
  content: React.ReactNode;
  closeModal?: () => void;
  closeLabel: string;
  titleProps?: { as?: React.ElementType };
  descriptionProps?: { as?: React.ElementType };
};

type ModalProps = {
  children: React.ReactNode;
  trigger: React.ReactNode;
  dialogProps?: {
    open?: boolean;
    onClose?: () => void;
    tw?: string;
    initialFocus?: React.MutableRefObject<HTMLElement | null>;
    static?: boolean;
    unmount?: undefined;
  } & { as?: React.ElementType };
  contentProps: ContentProps;
  dialogOverlayProps?: { as?: React.ElementType };
  titleProps?: { as?: React.ElementType };
  descriptionProps?: { as?: React.ElementType };
  title: string;
};

type TriggerProps = { openModal: () => void };

const Modal: React.FC<ModalProps> = ({
  children,
  trigger,
  dialogProps,
  dialogOverlayProps,
  open,
  ...remainingProps
}) => {
  let [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    if (open == isOpen) return;
    setIsOpen(open);
  }, [open]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <Fragment>
      {React.cloneElement(trigger, { onClick: openModal })}
      <Transition show={isOpen} as={Fragment}>
        <Dialog tw="relative z-10" onClose={closeModal} {...dialogProps}>
          <Transition.Child {...overlayTransitionProps} as={Fragment}>
            <Dialog.Overlay
              css={[
                tw`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity`,
              ]}
              {...dialogOverlayProps}
            />
          </Transition.Child>
          <Transition.Child {...contentTransitionProps} as={Fragment}>
            <div
              css={[
                tw`pointer-events-auto w-screen fixed inset-0 h-screen flex items-center justify-center`,
              ]}
            >
              <div
                css={[
                  tw`relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl w-full sm:p-6 md:max-w-6xl h-[95%]`,
                ]}
                {...remainingProps}
              >
                <Fragment>
                  <div
                    css={[tw`absolute top-0 right-0 pt-4 pr-4`]}
                  >
                    <button
                      type="button"
                      css={[
                        tw`bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`,
                      ]}
                      onClick={closeModal}
                    >
                      <span css={[tw`sr-only`]}>Close</span>
                      <XIcon css={[tw`h-6 w-6`]} aria-hidden="true" />
                    </button>
                  </div>
                  {children}
                </Fragment>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

const contentTransitionProps = {
  enter: tw`ease-out duration-300`,
  enterFrom: tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`,
  enterTo: tw`opacity-100 translate-y-0 sm:scale-100`,
  leave: tw`ease-in duration-200`,
  leaveFrom: tw`opacity-100 translate-y-0 sm:scale-100`,
  leaveTo: tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`,
};

const overlayTransitionProps = {
  enter: tw`ease-out duration-300`,
  enterFrom: tw`opacity-0`,
  enterTo: tw`opacity-100`,
  leave: tw`ease-in duration-200`,
  leaveFrom: tw`opacity-100`,
  leaveTo: tw`opacity-0`,
};

export default Modal;
