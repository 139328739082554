import React from "react";
import tw from "twin.macro";
import ControlledTextarea from "./ControlledTextarea";

const Textarea: React.FC = ({
  label,
  value,
  onChange,
  disabled,
  maxLength,
  ...remainingProps
}) => {
  return (
    <div css={[disabled && tw`pointer-events-none opacity-50`]}>
      <label
        htmlFor="comment"
        css={[tw`block text-sm font-medium text-gray-700`]}
      >
        {label}
      </label>
      <div css={[tw`mt-1`]}>
        <ControlledTextarea
          disabled={disabled}
          rows={4}
          css={[
            tw`shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md`,
          ]}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          {...remainingProps}
        />
      </div>
    </div>
  );
};

export default Textarea;
