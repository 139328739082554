import { PlayIcon, VideoCameraIcon, XIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { Button } from "../Button";
import { PreviewGalleryModal } from "../PreviewGallery";

const PreviewSelector = ({ themeID, initialCollection, label, disabled, value, onUpdate, ...remainingProps }) => {
  const [file, setFile] = useState(value);
  const [filePath, setFilePath] = useState('');
  const [fileType, setFileType] = useState('');

  useEffect(() => {
    if(onUpdate) {
      onUpdate(file)
    }

    if (!file) return;
    const parts = file.split(".");
    setFileType(parts[parts.length - 1]);
    setFilePath(`${process.env.GATSBY_AUTH_API_ROOT_URL}/Themes/${file}`);
  }, [file]);

  return (
    <div
      css={[disabled && tw`pointer-events-none opacity-50`]}
      {...remainingProps}
    >
      <div>
        <PreviewGalleryModal themeID={themeID} initialCollection={initialCollection} onSelect={(file) => console.log('PreviewGalleryModal:onSelect', file)} />
      </div>
    </div>
  );
};

export default PreviewSelector;
