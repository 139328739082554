import { TableIcon } from "@heroicons/react/outline";
import React from "react";
import tw from "twin.macro";
import { DownloadCardProps } from "./DownloadCard.d";
import numeral from 'numeral';

const DownloadCard: React.FC<DownloadCardProps> = ({
  label,
  fileType,
  fileSize,
  fileUrl,
  fileName
}) => {
  const fileTypeColors = { ".xlsx": "#008000" };
  // console.log('DownloadCard', label, fileType, fileSize, fileName, fileUrl)
  const download = fileName ?? label.replace('.', '_') + fileType;
  return (
    <li key={label} css={[tw`block shadow-sm rounded-md`]}>
      <a href={fileUrl} download={download} css={[tw`flex`]} target="_blank">
        <div
          css={[
            `background: ${fileTypeColors[fileType]}`,
            tw`flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md`,
          ]}
        >
          <TableIcon css={[tw`w-8`]} />
        </div>
        <div
          css={[
            tw`flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate`,
          ]}
        >
          <div css={[tw`flex-1 px-4 py-2 truncate`]}>
            <span css={[tw`text-gray-900 font-medium hover:text-gray-600`]}>
              {label}
            </span>
            {/* <p css={[tw`text-gray-500 text-xs whitespace-pre-wrap`]}> */}
            <p css={[tw`text-gray-700 text-sm truncate`]}>
              {download}
            </p>
            <p css={[tw`text-gray-500 text-sm`]}>
              {numeral(fileSize).format('0.00b')} | {fileType}
            </p>
          </div>
        </div>
      </a>
    </li>
  );
};

export default DownloadCard;
