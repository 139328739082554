import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";

class EventNewClient extends APIBaseClient {
  constructor() {
    super();
    this.baseEndpoint = "events";
  }

  public createEvent = async (form: any) => {
    const user = await new AuthClient().getUser()
    const token = user.tokenPayload?.access_token
    const event = await this.call("", HTTP_METHOD.POST, form, token);
    return event;
  };
}

export default EventNewClient;
