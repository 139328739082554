import React from "react";
import tw from "twin.macro";
import { CheckboxProps } from "./Form";

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  name,
  checked,
  onChange,
}) => {
  return (
    <div css={[tw`flex items-center`]}>
      <input
        id={name}
        name={name}
        type="checkbox"
        css={[
          tw`h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded`,
        ]}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={name} css={[tw`ml-2 block text-sm text-gray-900`]}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
