import React from "react";
import tw from "twin.macro";

const Select: React.FC = ({
  label,
  children,
  disabled,
  wrapperStyles,
  ...remainingProps
}) => {
  return (
    <div css={[disabled && tw`pointer-events-none opacity-50`, wrapperStyles]}>
      <label
        htmlFor={label.toLowerCase().replace(" ", "")}
        css={[tw`block text-sm font-medium text-gray-700`]}
      >
        {label}
      </label>
      <select
        id={label.toLowerCase().replace(" ", "")}
        name={label.toLowerCase().replace(" ", "")}
        css={[
          tw`mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md`,
        ]}
        {...remainingProps}
      >
        {children}
      </select>
    </div>
  );
};

export default Select;
