import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import UserClient from "../../../clients/User";
import { NotificationsContext } from "../../../context/Notifications";
import { addNotification } from "../../../context/Notifications/actions";
import { User } from "../../../global";
import { Button } from "../../Button";
import Container from "../../Container/Container";
import { Input } from "../../Form";
import Select from "../../Form/Select";
import PageHeading from "../../PageHeading/PageHeading";
import { Table, THead, TR, TH, TD, TBody } from "../../Table/TableElements";
import { UsersEditPageProps } from "./User.d";
import { Event } from "../../../clients/Events.d";
import AuthClient from "../../../clients/Auth";
import { navigate } from "gatsby";

const UsersEditPage: React.FC<UsersEditPageProps> = ({ userID }) => {
  const [notifications, dispatch] = useContext(NotificationsContext);
  const [data, setData] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);
  const [newEvents, setNewEvents] = useState(null);
  const [newEvent, setNewEvent] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);

  const client = new UserClient();

  useEffect(() => {
    if (!userID) return;

    const getUserData = async () => {
      try {
        const data = await client.find(userID);
        setData(data);
      } catch (err) {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      }
    };

    const getEventAdminData = async () => {
      try {
        const eventData = await client.getEvents(userID);
        setEventDetails(eventData);
      } catch (err) {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      }
    };

    const getNewEventData = async () => {
      try {
        const eventData = await client.getNewEvents(userID);
        setNewEvents(eventData);
        setNewEvent(eventData[0]?.eventID);
      } catch (err) {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      }
    };

    getUserData();
    getEventAdminData();
    getNewEventData();
  }, []);

  const updateField = (key: string, value: string) => {
    const newData = {
      ...data,
      [key]: value,
    };
    setData(newData);
  };

  const updateAdminEvents = (action: string, event: string) => {
    if (event === "Select an Event") {
      return;
    }
    let updatedEventDetails = [];
    let updatedNewEvents = [];
    if (action === "add") {
      const eventToAdd = newEvents.filter((e: Event) => e.eventID === event)[0];
      updatedEventDetails = [eventToAdd, ...eventDetails];
      updatedNewEvents = newEvents.filter((e: Event) => e.eventID !== event);
    }
    if (action === "remove") {
      updatedEventDetails = eventDetails.filter(
        (e: Event) => e.eventID !== event
      );
      const eventToAdd = eventDetails.filter(
        (e: Event) => e.eventID === event
      )[0];
      updatedNewEvents = [eventToAdd, ...newEvents];
    }
    setEventDetails(updatedEventDetails);
    setNewEvents(updatedNewEvents);
    if (updatedNewEvents.length > 0) {
      setNewEvent(updatedNewEvents[0]?.eventID);
    } else {
      setNewEvent("Select an Event");
    }
  };

  const validatePassword = (password: string, confirmation: string) => {
    return password === confirmation;
  };

  const handleSubmitForm = async () => {
    try {
      const client = new UserClient();
      const updateRes = await client.update(data);
      const eventsUpdateRes = await client.updateEvemtAdmin(data.userID, eventDetails);
      dispatch(
        addNotification({
          title: "Success",
          description: "The user was updated.",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occurred.",
            type: "error",
          })
        );
      }
    }
  };

  const handlePasswordReset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSubmitting(true);
    const isValid = validatePassword(password, passwordConfirmation);
    if (isValid === false) {
      setIsSubmitting(false);
      dispatch(
        addNotification({
          title: "Error",
          description: "Please make sure passwords match and try again.",
          type: "error",
        })
      );
      return;
    }

    try {
      const authClient = new AuthClient();
      const loggedInUser = await authClient.getUser();
      const res = await authClient.resetPassword(loggedInUser, data.userID, password);
      dispatch(
        addNotification({
          title: "Success",
          description: "Password was updated!",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description:
              "Unable to update your profile information. Please try again.",
            type: "error",
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  const handleUserDelete = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsDeleting(true);
    try {
      const res = await client.delete(data.userID);
      dispatch(
        addNotification({
          title: "Success",
          description: "User was deleted!",
          type: "success",
        })
      );
      navigate(`/users`);
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description:
              "Unable to delete user. Please try again.",
            type: "error",
          })
        );
      }
    }
    setIsDeleting(false);
  };

  return (
    <Container>
      <PageHeading
        buttons={[
          {
            label: "Cancel",
            variations: { light: true },
            link: "/users",
          },
          {
            label: "Save",
            variations: { primary: true },
            onClick: handleSubmitForm,
          },
        ]}
      >
        Edit User
      </PageHeading>

      <form css={tw`space-y-6 w-1/2`}>
        <Input
          label="First Name"
          name="first-name"
          value={data?.firstName ? data.firstName : ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateField("firstName", e.target.value)
          }
        />
        <Input
          label="Last Name"
          name="last-name"
          value={data?.lastName ? data.lastName : ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateField("lastName", e.target.value)
          }
        />
        <Input
          label="Email"
          name="email"
          value={data?.email ? data.email : ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateField("email", e.target.value)
          }
        />
        <hr />
        <Input
          label="Password"
          name="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
        <Input
          label="Password Confirmation"
          name="passwordConfirmation"
          type="password"
          value={passwordConfirmation}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPasswordConfirmation(e.target.value)
          }
        />
        <Button onClick={handlePasswordReset} primary loading={isSubmitting}>
          Reset Password
        </Button>
        <Button onClick={handleUserDelete} primary loading={isDeleting}>
          Delete User
        </Button>
        <hr />
        <Select
          label="Role"
          name="role"
          value={data?.role}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateField(e.target.name, e.target.value)
          }
          css={tw`w-40`}
        >
          <option value="Standard">Standard Visitor</option>
          <option value="Event Admin">Event Admin</option>
          <option value="Admin">Super Admin</option>
        </Select>
      </form>

      {data?.role === "Event Admin" && (
        <Table css={tw`mt-6`}>
          <THead>
            <TR>
              <TH>Event Name</TH>
              <TH>Action</TH>
            </TR>
          </THead>
          <TBody>
            <TR>
              <TD>
                <Select
                  label=""
                  name="newEvent"
                  value={newEvent}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewEvent(e.target.value)
                  }
                  css={tw`w-full`}
                >
                  <option value="Select an Event">Select an Event</option>
                  {newEvents &&
                    newEvents.map((e: Event, i: number) => (
                      <option value={e.eventID}>{e.title}</option>
                    ))}
                </Select>
              </TD>
              <TD>
                <Button
                  primary
                  css={[tw`col-span-6 lg:col-span-2`]}
                  onClick={(e) => updateAdminEvents("add", newEvent)}
                >
                  Add
                </Button>
              </TD>
            </TR>
            {eventDetails &&
              eventDetails.map((e: Event, i: number) => (
                <TR key={i}>
                  <TD>{e?.title}</TD>
                  <TD>
                    <Button
                      primary
                      css={[tw`col-span-6 lg:col-span-2`]}
                      onClick={(event) =>
                        updateAdminEvents("remove", e?.eventID)
                      }
                    >
                      Remove
                    </Button>
                  </TD>
                </TR>
              ))}
          </TBody>
        </Table>
      )}
    </Container>
  );
};

export default UsersEditPage;
