import { navigate } from "gatsby";
import React, {
  EventHandler,
  FormEventHandler,
  ReactEventHandler,
  useContext,
  useEffect,
  useState,
} from "react";
import tw from "twin.macro";
import { Alert } from "../Alert";
import { Button } from "../Button";
import { Checkbox, Input } from "../Form";
import { GlobalStyles } from "../GlobalStyles";
import { UserContext } from "../../context/User/UserContext";
import { StaticImage } from "gatsby-plugin-image";
import Logo from "../Logo/Logo";
import { Loader } from "../Icons";
import { Layout } from "../Layout";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true)
  }, [])

  const { login, isLoggedIn } = useContext(UserContext);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);
    try {
      const user = await login({ email, password });
      if (user) {
        navigate("/");
      } else {
        setError("Invalid username or password.");
      }
    } catch (error) {
      if (Object.hasOwn(error, "message")) {
        setError(error.message);
      } else {
        setError("An unspecified error has occurred: " + JSON.stringify(error));
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Layout>
      {isMount ? (
        <div css={[tw`min-h-full flex`]}>
          <div
            css={[
              tw`flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24`,
            ]}
          >
            <div css={[tw`mx-auto w-full max-w-sm lg:w-96`]}>
              <div>
                <Logo css={[tw`w-40`]} />
                <h2 css={[tw`mt-6 text-3xl font-extrabold text-gray-900`]}>
                  Sign in to your account
                </h2>
              </div>

              <div css={[tw`mt-8`]}>
                {error && <Alert error title={error} />}
                <div css={[tw`mt-6`]}>
                  <form action="#" method="POST" css={[tw`space-y-6`]}>
                    <Input
                      label="Email address"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                      label="Password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <div css={[tw`flex items-center justify-between`]}>
                      <Checkbox
                        label="Remember me"
                        name="remember-me"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />

                      {/* <div css={[tw`text-sm`]}>
                        <a
                          href="#"
                          css={[
                            tw`font-medium text-primary-600 hover:text-primary-500`,
                          ]}
                        >
                          Forgot your password?
                        </a>
                      </div> */}
                    </div>

                    <div>
                      <Button
                        onClick={handleSubmitForm}
                        primary
                        block
                        loading={isSubmitting}
                      >
                        Sign in
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div css={[tw`hidden lg:block relative w-0 flex-1`]}>
            <StaticImage
              css={[tw`absolute inset-0 h-full w-full object-cover`]}
              imgStyle={{objectPosition: "top"}}
              src="../../images/kioskadmin.jpg"
              alt=""
            />
          </div>
        </div>
      ) : (
        <div css={tw`py-6`}>
          <Loader css={tw`animate-spin mx-auto h-8 w-8`} />
        </div>
      )}
    </Layout>
  );
};

export default LoginPage;
