import { ExclamationIcon } from "@heroicons/react/solid";
import tw from "twin.macro";
import { AlertProps } from "./Alert.d";

const Alert: React.FC<AlertProps> = ({
  title,
  description = "",
  warning = false,
  error = false,
  info = false,
  success = false,
}) => {
  return (
    <div
      css={[
        tw` border-l-4 p-4`,
        warning && tw`bg-yellow-50 border-yellow-400`,
        error && tw`bg-red-50 border-red-400`,
        success && tw`bg-green-50 border-green-400`
      ]}
    >
      <div css={[tw`flex`]}>
        <div css={[tw`flex-shrink-0`]}>
          <ExclamationIcon
            css={[
              tw`h-5 w-5 `,
              warning && tw`text-yellow-400`,
              error && tw`text-red-400`,
              success && tw`text-green-400`
            ]}
            aria-hidden="true"
          />
        </div>
        <div css={[tw`ml-3`]}>
          <p
            css={[
              tw`text-sm font-medium `,
              warning && tw`text-yellow-700`,
              error && tw`text-red-700`,
              success && tw`text-green-700`
            ]}
          >
            {title}{" "}
            {description && (
              <span
                css={[
                  tw`font-normal`,
                  warning && tw`text-yellow-700 hover:text-yellow-600`,
                  error && tw`text-red-700 hover:text-red-600`,
                  success && tw`text-green-700 hover:text-green-600`
                ]}
              >
                {description}
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Alert;
