import React from "react";
import tw from "twin.macro";
import { camelToTitleCase } from "../../../../../helpers";
import { Input } from "../../../../Form";
import ImageSelector from "../../../../Form/ImageSelector";
import Textarea from "../../../../Form/Textarea";
import SectionHeading from "../../../../SectionHeading/SectionHeading";
import {
  Table,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "../../../../Table/TableElements";
import Toggle from "../../../../Toggle/Toggle";

const Station2Details = ({ eventConfigID, data, updateStationValue }) => {
  const updateStation2Details = (
    parentKey: string,
    key: string,
    value: any
  ) => {
    const newData = {
      ...data,
      [parentKey]: {
        ...data[parentKey],
        [key]: value,
      },
    };
    // console.log(newData);
    updateStationValue(2, "details", newData);
  };

  const updateStation2DetailsValue = (key: string, value: any) => {
    const newData = {
      ...data,
    };
    newData[key] = value;
    // console.log(newData);
    updateStationValue(2, "details", newData);
  };

  const updateOption = (index: string, key: string, value: string) => {
    const indexes = index.split(".").map((index) => {
      return parseInt(index, 10) - 1;
    });

    if (indexes.length === 1) updatedTier1Options(indexes[0], key, value);
    if (indexes.length === 2) updateTier2Option(indexes, key, value);
    if (indexes.length === 3) updateTier3Option(indexes, key, value);
  };

  const updatedTier1Options = (index: number, key: string, value: any) => {
    let updatedCareerOptions = [...data.careerOptions.options];
    const updatedArrayItem = {
      ...data.careerOptions.options[index],
      [key]: value,
    };
    updatedCareerOptions[index] = updatedArrayItem;
    updateStation2Details("careerOptions", "options", updatedCareerOptions);
  };

  const updateTier2Option = (indexes: number[], key: string, value: any) => {
    let updatedCareerOptions = [...data.careerOptions.options];
    let updatedOptionsArray = [
      ...data.careerOptions.options[indexes[0]].options,
    ];

    const updatedArrayItem = {
      ...data.careerOptions.options[indexes[0]].options[indexes[1]],
      [key]: value,
    };
    updatedOptionsArray[indexes[1]] = updatedArrayItem;
    updatedCareerOptions[indexes[0]].options = [...updatedOptionsArray];
    updateStation2Details("careerOptions", "options", updatedCareerOptions);
  };

  const updateTier3Option = (indexes: number[], key: string, value: any) => {
    let updatedCareerOptions = [...data.careerOptions.options];
    let updatedOptionsArray = [
      ...data.careerOptions.options[indexes[0]].options,
    ];

    const updatedArray = [
      ...data.careerOptions.options[indexes[0]].options[indexes[1]].options,
    ];
    const updatedArrayItem = {
      ...data.careerOptions.options[indexes[0]].options[indexes[1]].options[
        indexes[2]
      ],
      [key]: value,
    };
    updatedArray[indexes[2]] = updatedArrayItem;
    updatedOptionsArray[indexes[1]].options = [...updatedArray];
    updatedCareerOptions[indexes[0]].options = [...updatedOptionsArray];
    updateStation2Details("careerOptions", "options", updatedCareerOptions);
  };

  return (
    <div>
      <SectionHeading>Details</SectionHeading>
      <Textarea
        label="Career Start Screen Message"
        name="screenMessage"
        value={data.careerOptions.screenMessage}
        onChange={(e) =>
          updateStation2Details("careerOptions", e.target.name, e.target.value)
        }
        css={tw`w-1/2 mb-4`}
        maxLength={50}
      />
      <section css={[tw`grid gap-4 mb-16`]}>
        {data.careerOptions.options?.map((tier1Option, i) => {
          if (!tier1Option?.options) return;
          return (
            <Table key={`${i + 1}`}>
              <THead>
                <TH></TH>
                <TH>Label</TH>
                <TH>Icon</TH>
                <TH>Screen Message</TH>
              </THead>
              <TBody>
                <LabelsAndAssetsRow
                  i={`${i + 1}`}
                  label={tier1Option.label}
                  icon={tier1Option.icon}
                  screenMessage={tier1Option.screenMessage}
                  css={[tw`font-bold bg-gray-300`]}
                  onChange={updateOption}
                  showScreenMessage={true}
                  eventConfigID={eventConfigID}
                />
                {tier1Option.options.map((tier2Option, j) => (
                  <>
                    <LabelsAndAssetsRow
                      key={`${i + 1}.${j + 1}`}
                      i={`${i + 1}.${j + 1}`}
                      label={tier2Option.label}
                      icon={tier2Option.icon}
                      screenMessage={tier2Option.screenMessage}
                      css={[tw`font-medium bg-gray-100`]}
                      onChange={updateOption}
                      showScreenMessage={true}
                      eventConfigID={eventConfigID}
                    />
                    {tier2Option.options &&
                      tier2Option.options.map((tier3Option, k) => (
                        <LabelsAndAssetsRow
                          key={`${i + 1}.${j + 1}.${k + 1}`}  
                          i={`${i + 1}.${j + 1}.${k + 1}`}
                          label={tier3Option.label}
                          icon={tier3Option.icon}
                          screenMessage={tier3Option.screenMessage}
                          onChange={updateOption}
                          showScreenMessage={false}
                          eventConfigID={eventConfigID}
                        />
                      ))}
                  </>
                ))}
              </TBody>
            </Table>
          );
        })}
      </section>
      <SectionHeading>Results Config</SectionHeading>
      <section css={[tw`grid gap-4 mb-16`]}>
        <Table>
          <THead>
            <TH>Module Name</TH>
            <TH>Value</TH>
          </THead>
          <TBody>
            {Object.keys(data.careerResults)
              .filter((key) => !key.includes("Icon"))
              .filter(
                (key) =>
                  !key.includes("editVisible") && !key.includes("editText")
              )
              .sort((a, b) => {
                // console.log("sort: ", a, b);
                if (a === "dykMessage1" && b === "dykMessage2") {
                  return 1;
                }
                if (a === "dykMessage2" && b === "dykMessage1") {
                  return -1;
                }
                return 0;
              })
              .map((key) => {
                const option = data.careerResults[key];
                const icon =
                  data.careerResults[key.replace("Text", "") + "Icon"];
                // console.log(data.careerResults[key])

                let maxLength = 90;

                let label = camelToTitleCase(key);

                if (label === "Dyk Message1") {
                  label = "Banner Job Openings Message";
                }

                if (label === "Dyk Message2") {
                  label = "Banner Average Annual Wage Message";
                }

                if (label === "Job Message") {
                  return;
                }

                if (label === "Hiring Text" || label === "Next Step Text") {
                  maxLength = 35; 
                }

                return (
                  <TR>
                    <TD>{label}</TD>
                    <TD>
                      {key.endsWith("Visible") ? (
                        <Toggle
                          enabled={option}
                          label=""
                          onChange={(isChecked: boolean) =>
                            updateStation2Details(
                              "careerResults",
                              key,
                              isChecked
                            )
                          }
                        />
                      ) : (
                        <Textarea
                          name={key}
                          value={option}
                          // css={tw`w-36 md:w-max`}
                          css={tw`w-full`}
                          onChange={(e) =>
                            updateStation2Details(
                              "careerResults",
                              e.target.name,
                              e.target.value
                            )
                          }
                          maxLength={maxLength}
                        />
                      )}
                    </TD>
                  </TR>
                );
              })}
          </TBody>
        </Table>
      </section>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
  );
};

const LabelsAndAssetsRow = ({
  i,
  label,
  icon,
  breadcrumb,
  screenMessage,
  headerImage,
  footerImage,
  onChange,
  showScreenMessage,
  eventConfigID,
  ...remainingProps
}) => {
  // console.log('LabelsAndAssetsRow: ', i, label, screenMessage);
  return (
    <TR {...remainingProps}>
      <TD>Option {i}</TD>
      <TD>{label}</TD>
      <TD css={[tw``]}>
        <ImageSelector
          eventConfigID={eventConfigID}
          initialCollection="Job Icons"
          value={icon}
          css={tw`max-w-[12rem]`}
          onUpdate={(file: string) => onChange(i, "icon", file)}
        />
      </TD>
      <TD>
        {showScreenMessage && (
          <Textarea
            label=""
            name="screenMessage"
            value={screenMessage}
            onChange={(e) => onChange(i, e.target.name, e.target.value)}
            // css={tw`w-48`}
            css={tw`w-full`}
            maxLength={50}
          />
        )}
      </TD>
    </TR>
  );
};

export default Station2Details;
