import tw from "twin.macro";

export const TBody = tw.tbody`divide-y divide-gray-200 bg-white`;
export const THead = tw.thead`bg-gray-50`;
export const TFoot = tw.thead`bg-gray-50`;
export const TH = tw.th`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6`;
export const TD = tw.td`whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6`;
export const TR = tw.tr``;

export const Table = ({ children, ...remainingProps }) => (
  <div 
    css={[ tw`overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg`]}
    {...remainingProps}
  >
    <table css={[tw`min-w-full divide-y divide-gray-300`]}>{children}</table>
  </div>
);
