import { HTTP_METHOD } from "../global.d";
import { dateToFormattedString } from "../helpers";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";
import { KioskAnalyticsRequest, KioskAnalyticsResponse } from "./KioskAnalytics.d";

class KioskAnalyticsClient extends APIBaseClient {
  constructor() {
    super();
    this.baseEndpoint = `events`;
  }
  private loggedInUser = new AuthClient().getUser();
  private token = this.loggedInUser.tokenPayload?.access_token;

  public get = async (
    eventId: string,
    startDate?: string,
    endDate?: string,
    visitorType?: string
  ): Promise<KioskAnalyticsResponse> => {
    let options: KioskAnalyticsRequest = {};
    if (startDate && endDate) {
      options.StartDate = startDate;
      options.EndDate = endDate;
    }
    if (visitorType) options.VisitorType = visitorType;

    try {
      const res = await this.call(
        `${eventId}/kiosk-analytics?${new URLSearchParams(options).toString()}`,
        HTTP_METHOD.GET,
        this.token
      );
      return res;
    } catch (error) {
      throw error;
    }
  };
}

export default KioskAnalyticsClient;
