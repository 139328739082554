import { useContext, useEffect, useState } from "react"
import tw from "twin.macro";
import { NotificationsContext } from "../../context/Notifications";
import { removeNotification } from "../../context/Notifications/actions";
import { ExclamationIcon, XIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import Transition from "../Transition/Transition";
import { NotificationProps } from "./Notification.d";


const Notification: React.FC<NotificationProps> = ({title, description, type, id}) => {
  const [notifications, dispatch] = useContext(NotificationsContext)
  const icons = {
    error: ExclamationCircleIcon,
    success: CheckCircleIcon
  }

  const Icon = icons[type] || ExclamationIcon

  useEffect(() => {
    setTimeout(() => {
      dispatch(removeNotification(id))
    }, 15000)
  }, [])

  return (
    <Transition 
      show={true} 
      enter={tw`transition-opacity duration-75`}
      enterFrom={tw`opacity-0`}
      enterTo={tw`opacity-100`}
      leave={tw`transition-opacity duration-150`}
      leaveFrom={tw`opacity-100`}
      leaveTo={tw`opacity-0`}
    >
      <div 
        css={[
          tw`w-96 max-w-full bg-white text-sm text-gray-500 shadow-lg mx-auto mb-2 p-3 bg-clip-padding rounded border-l-8`,
          tw`transition transform origin-right scale-x-0 scale-x-100 duration-150	`,
          type === "warning" && tw`border-yellow-400 bg-yellow-50 text-yellow-600`,
          type === "error" && tw`border-red-400 bg-red-50 text-red-600`,
          type === "success" && tw`border-green-400 bg-green-50 text-green-600`,
        ]} 
        role="alert"
      >
        <div css={tw`flex justify-between items-center mb-2`}>
          <span css={tw`flex`}>
            <Icon css={tw`h-5 w-5 mr-1`} aria-hidden="true" />
            <p css={tw`font-bold`}>{title}</p>
          </span>
          <button 
            css={tw`w-4 h-4 ml-2 opacity-60 hocus:opacity-100`} 
            aria-label="Close"
            onClick={() => dispatch(removeNotification(id))}
          >
            <XIcon />
          </button>
        </div>
        <div css={[
          tw`text-gray-700`,
          type === "warning" && tw`text-yellow-700`,
          type === "error" && tw`text-red-700`,
          type === "success" && tw`text-green-700`
        ]}>
          {description}
        </div>
      </div>
    </Transition>
  );
};

export default Notification