import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";
import { Theme, ThemePreview } from './Themes.d'

class ThemesClient extends APIBaseClient {
    constructor() {
        super()
        this.baseEndpoint = 'themes'
    }
    private loggedInUser = new AuthClient().getUser()
    private token = this.loggedInUser.tokenPayload?.access_token


    public get = async (): Promise<Theme[]> => {
        const res = await this.call('', HTTP_METHOD.GET, {}, this.token)
        return await res
    }

    public getOne = async (id: string): Promise<Theme> => {
        const res = await this.call(id, HTTP_METHOD.GET, {}, this.token)
        return await res
    }

    public create = async (newTheme: Theme): Promise<Theme> => {
        const res = await this.call('', HTTP_METHOD.POST, newTheme, this.token)
        return await res
    }

    public update = async (updatedTheme: Theme): Promise<Theme> => {
        const res = await this.call(updatedTheme.themeID, HTTP_METHOD.PUT, updatedTheme, this.token)
        return await res
    }

    public delete = async (id: string): Promise<void> => {
        const res = await this.call(id, HTTP_METHOD.DELETE, {}, this.token)
        return await res
    }
}

export default ThemesClient