import React, { useLayoutEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { InputProps } from "./Form";

const ControlledInput: React.FC<InputProps> = ({
  name,
  label,
  value,
  onChange,
  onMouseOver,
  disabled,
  readOnly,
  type,
  placeholder,
  step,
  min,
  max,
  maxLength,
  style,
  ...remainingProps
}) => {
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  useLayoutEffect(() => {
    const input = ref.current;
    if (input && type === 'text') input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e);
  };

  return (
    <input
      ref={ref}
      id={name}
      name={name}
      type={type ? type : "text"}
      autoComplete={name}
      required
      value={value}
      onChange={handleChange}
      onMouseOver={onMouseOver}
      placeholder={placeholder}
      step={step}
      min={min}
      max={max}
      maxLength={maxLength}
      readOnly={readOnly}
      css={[
        tw`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm`,
      ]}
      style={style}
    />
  );
};

export default ControlledInput;
