import React, { useState } from 'react'
import tw from 'twin.macro';
import { Button } from '../Button'
import { Modal } from '../Modal'
import KioskAdminCard from './KioskAdminCard';
import kioskadmin from '../../images/kioskadmin.jpg'

const KioskAdminEventPreview = ({ data, stationName, ...remainingProps}) => {
    const [open, setOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null)

    const handleClick = (e) => {
        if(!open) {
            setSelectedCard(e.target.value)
        }
        setOpen(true)
    }

    return (
        <div onClickCapture={(e) => handleClick(e)} {...remainingProps}>
            <Modal
                open={open}
                trigger={
                    <Button secondary value={stationName} css={tw`w-full`}>
                        Preview Kiosk Admin Screen
                    </Button>
                }
                css={[tw`overflow-y-scroll bg-cover	bg-no-repeat bg-cover md:max-w-5xl`, `background-image: url('${kioskadmin}')`]}
            >
                <div css={tw`grid grid-cols-1 gap-4 justify-center py-6 overflow-hidden mx-auto md:max-w-xl md:grid-cols-2`}>
                    {data?.map((kiosk) => (
                        <KioskAdminCard key={kiosk.name} icon={kiosk.icon} iconLabel={kiosk.name} selected={kiosk.name === selectedCard} />
                        ))}
                </div>
            </Modal>

        </div>
    )
}

export default KioskAdminEventPreview
