import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import {
  Table,
  TBody,
  TD,
  TFoot,
  TH,
  THead,
  TR,
} from "../../Table/TableElements";
import KioskStatusClient from "../../../clients/KioskStatus";
import { DashboardTabProps } from "./Dashboard.d";
import { KioskStatusResponse } from "../../../clients/KioskStatus.d";

const StatusPage: React.FC<DashboardTabProps> = ({ eventID }) => {
  const [data, setData] = useState<KioskStatusResponse>(null);

  useEffect(() => {
    if (!eventID) return;
    const getData = async () => {
      const client = new KioskStatusClient();
      const data = await client.get(eventID);
      setData(data);
    };
    getData();
  }, [eventID]);

  return (
    <div tw="py-4">
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      {data && <StatusTable data={data} />}
    </div>
  );
};

const StatusTable: React.FC<{ data: KioskStatusResponse }> = ({ data }) => {
  const headings = ["Online", "Configs Current"];
  const rows: Array<any> = [];
  Object.keys(data).map((key: string) => {
    if (key.startsWith("station")) {
      const stationNumber = Number(key[7]);
      const valueType = key.substring(8);
      const row = rows[stationNumber];
      if (typeof row !== "object") {
        rows[stationNumber] = {
          Label: null,
          BadgesScanned: null,
          Visitors: null,
          AvgDuration: null,
          CompletionRate: null,
          ContinuationRate: null,
        };
      }
      rows[stationNumber][valueType] = data[key];
    }
  });
  return (
    <Table>
      <THead>
        <TR>
          <TH></TH>
          {headings.map((heading, i) => (
            <TH key={i} css={[tw`text-center`]}>{heading}</TH>
          ))}
        </TR>
      </THead>
      <TBody>
        {rows.map((row, i) => (
          <TR key={i}>
            <TD>{row.Label}</TD>
            <TD
              css={[
                tw`text-center`,
                row.ActiveCount !== row.ConfiguredCount &&
                  tw`font-bold text-primary-600`,
              ]}
            >
              {row.ActiveCount}/{row.ConfiguredCount}
            </TD>
            <TD
              css={[
                tw`text-center`,
                row.UpdatedCount !== row.ConfiguredCount &&
                  tw`font-bold text-primary-600`,
              ]}
            >
              {row.UpdatedCount}/{row.ConfiguredCount}
            </TD>
          </TR>
        ))}
      </TBody>
    </Table>
  );
};

export default StatusPage;
