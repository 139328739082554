import { UploadIcon } from "@heroicons/react/solid";
import React, { useEffect, useRef, useState, useContext } from "react";
import tw from "twin.macro";
import PreviewClient from "../../clients/Preview";
import { Preview } from "../../clients/Preview.d";
import { NotificationsContext } from "../../context/Notifications";
import { addNotification } from "../../context/Notifications/actions";
import { Button } from "../Button";
import { Input } from "../Form";
import Select from "../Form/Select";
import PreviewList from "./PreviewList";

const PreviewGallery = ({ themeID, initialCollection, selectImage }) => {
  const [previews, setPreviews] = useState<Preview[]>(null);
  const [filteredPreviews, setFilteredPreviews] = useState<Preview[]>(null);
  const [collections, setCollections] = useState<string[]>(null);
  const [activeCollection, setActiveCollection] = useState<string>(null);
  const [selectedPreview, setSelectedPreview] = useState<Preview>(null);
  const fileFieldRef = useRef();
  const [notifications, dispatch] = useContext(NotificationsContext);

  useEffect(() => {
    if (!previews) return;
    const newFilteredPreviews = previews.filter(
      (preview) =>
        preview.collection &&
        preview.collection.toLowerCase() === activeCollection.toLowerCase()
    );
    newFilteredPreviews.sort((a, b) => a.sortOrder - b.sortOrder);
    setFilteredPreviews(newFilteredPreviews);
  }, [activeCollection]);

  useEffect(() => {
    if (!themeID) return;

    const getPreviewsAndCollections = async () => {
      const client = new PreviewClient();
      let previews = await client.get(themeID, null, 'Active');
      let activePreviews = previews;
      let inactivePreviews = await client.get(themeID, null, 'Inactive');
      previews.push(...inactivePreviews);
      if (initialCollection) {
        setActiveCollection(initialCollection);
        activePreviews = activePreviews?.filter(
          (a) => a.collection === initialCollection
        );
      } else {
        setActiveCollection("");
      }

      setPreviews(previews);
      setFilteredPreviews(activePreviews);
      const collections = ['Primary Color A', 'Primary Color B', 'Primary Color C', 'Primary Color D', 'Primary Color E', 'Primary Color F', 'Secondary Color A', 'Secondary Color B', 'Secondary Color C', 'Secondary Color D', 'Secondary Color E', 'Secondary Color F', 'Secondary Color G', 'Secondary Color H', 'Secondary Color I', 'Secondary Color J', 'Secondary Color K', 'Secondary Color L', 'Secondary Color M', 'Secondary Color N', 'Transparent Color A', 'Transparent Color B', 'Transparent Color C', 'Transparent Color D', 'Transparent Color E', 'Transparent Color F', 'Transparent Color G', 'Transparent Color H', 'Other Color A', 'Other Color B', 'Other Color C', 'Other Color D', 'Other Color E', 'Other Color F', 'Other Color G', 'Other Color H', 'Other Color I', 'Other Color J', 'Other Color K', 'Other Color L'];
      setCollections(collections);
    };
    getPreviewsAndCollections();
  }, [themeID, initialCollection]);

  const handleUploadClick = () => {
    if (!fileFieldRef?.current) return;
    fileFieldRef.current.click();
  };

  const handleUpload = async (e) => {
    if (e.target.files.length < 1) return;
    let newPreviews = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const newPreview = await new PreviewClient().create(
        themeID,
        file,
        activeCollection
      );
      newPreviews.push(newPreview[0]);
    }
    let newFilteredPreviews = [...filteredPreviews, ...newPreviews];
    newFilteredPreviews = newFilteredPreviews.map((p, i) => {
      p.sortOrder = i
      return p;
    });
    newFilteredPreviews.sort((a, b) => a.sortOrder - b.sortOrder);
    await new PreviewClient().update(themeID, newFilteredPreviews);
    setFilteredPreviews(newFilteredPreviews);
    setPreviews([...previews, ...newPreviews]);
    setSelectedPreview(newFilteredPreviews[newFilteredPreviews.length - 1]);
  };

  const handleUpdate = (key: string, value: any) => {
    if (key === 'sortOrder') {
      handleSortOrderUpdate(value);
      return;
    }

    const updatedPreview = {
      ...selectedPreview,
      [key]: value,
    };
    const index = filteredPreviews.findIndex(
      (a) => a.themePreviewID === selectedPreview.themePreviewID
    );
    let newPreviews = [...filteredPreviews];
    newPreviews[index] = updatedPreview;
    if (selectedPreview.collection !== updatedPreview.collection) {
      newPreviews = newPreviews.filter((a) => a.collection === activeCollection);
    }
    setFilteredPreviews([...newPreviews]);
    setSelectedPreview(updatedPreview);
    updatePreviews([updatedPreview]);
  };

  const handleSortOrderUpdate = (value: any) => {
    const prevValue = selectedPreview.sortOrder;
    const updatedPreview = {
      ...selectedPreview,
      sortOrder: value,
    };
    let newPreviews = [...filteredPreviews];
    newPreviews[prevValue] = newPreviews[value];
    newPreviews[value] = updatedPreview;
    newPreviews = newPreviews.map((p, i) => {
      p.sortOrder = i
      return p;
    });
    newPreviews.sort((a, b) => a.sortOrder - b.sortOrder);
    setFilteredPreviews(newPreviews);
    setSelectedPreview(updatedPreview);
    updatePreviews(newPreviews);
  };

  const updatePreviews = async (previews: Preview[]) => {
    try {
      const res = await new PreviewClient().update(themeID, previews);
      dispatch(
        addNotification({
          title: "Success",
          description: "Images have been updated.",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occured.",
            type: "error",
          })
        );
      }
    }
  };

  const deletePreviews = async (previews: Preview[]) => {
    try {
      const res = await new PreviewClient().delete(themeID, previews);
      const previewIDs = previews.map((a) => a.themePreviewID);
      const newPreviews = filteredPreviews.filter(
        (a) => !previewIDs.includes(a.themePreviewID)
      );
      setFilteredPreviews([...newPreviews]);
      setSelectedPreview(newPreviews[0]);
      dispatch(
        addNotification({
          title: "Success",
          description: "Image has been deleted.",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occured.",
            type: "error",
          })
        );
      }
    }
  };

  return (
    <div css={[tw`flex flex-col h-full`]}>
      {/* <pre>{JSON.stringify(previews, null, 2)}</pre> */}
      <header css={[tw`grid grid-cols-12 gap-4 items-end py-4`]}>
        <Select
          label="Collection"
          value={activeCollection}
          onChange={(e) => setActiveCollection(e.target.value)}
          wrapperStyles={[tw`col-span-5 sm:col-span-5 lg:col-span-4`]}
        >
          {collections &&
            collections.map((collection) => (
              <option key={collection} value={collection}>
                {collection}
              </option>
            ))}
        </Select>
        <Button
          primary
          css={[tw`col-span-6 lg:col-span-2`]}
          onClick={handleUploadClick}
        >
          <UploadIcon css={[tw`h-4 mr-2`]} />
          Upload New
        </Button>
        <input
          type="file"
          css={[tw`hidden`]}
          ref={fileFieldRef}
          onChange={handleUpload}
          multiple
        />
      </header>
      {filteredPreviews && (
        <PreviewList
          previews={filteredPreviews}
          collections={collections}
          selectImage={selectImage}
          selectedPreview={selectedPreview}
          setSelectedPreview={setSelectedPreview}
          updatePreviews={updatePreviews}
          handleUpdate={handleUpdate}
          deletePreviews={deletePreviews}
        />
      )}
    </div>
  );
};

export default PreviewGallery;
