import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";
import { Question } from "./Questionnaire.d"

class QuestionnaireClient extends APIBaseClient {
    constructor() {
        super()
        this.baseEndpoint = 'questions'
    }
    private loggedInUser = new AuthClient().getUser()
    private token = this.loggedInUser.tokenPayload?.access_token

    public get = async () => {
        const res = await this.call("?IsAdmin=true", HTTP_METHOD.GET)
        return res
    }

    public update = async (question: Question) => {
        const res = await this.call(question.questionID, HTTP_METHOD.PUT, question, this.token);
        return res
    }

    public create = async (question: Question) => {
        const res = await this.call("", HTTP_METHOD.POST, question, this.token)
        return res
    }
}

export default QuestionnaireClient