import { HTTP_METHOD } from "../global.d";
import { dateToFormattedString } from "../helpers";
import APIBaseClient from "./APIBase";
import { KioskStatusResponse } from "./KioskStatus.d"

class KioskStatusClient extends APIBaseClient {
    constructor() {
        super()
        this.baseEndpoint = `events`
    }

    public get = async (eventId: string): Promise<KioskStatusResponse> => {
        try {
            const res = await this.call(`${eventId}/kiosk-status`, HTTP_METHOD.GET)
            return res
        } catch (error) {
            throw error
        }
    }
}

export default KioskStatusClient