import React, { useContext, useEffect, useState } from "react";
import { NotificationsContext } from "../../../context/Notifications";
import { addNotification } from "../../../context/Notifications/actions";
import Container from "../../Container/Container";
import QuestionnaireClient from "../../../clients/Questionnaire";
import QuestionnaireTab from "../Events/edit/QuestionnaireTab";
import PageHeading from "../../PageHeading/PageHeading";
import Question from "../../../clients/Questionnaire";
import QuestionnaireHeaderClient from "../../../clients/QuestionnaireHeader";

const QuestionnaireListPage = () => {
  const [data, setData] = useState(null);
  const [notifications, dispatch] = useContext(NotificationsContext);

  const client = new QuestionnaireClient();

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const data = await client.get();
        setData({
          data: {
            questions: data,
          },
        });
      } catch (err) {
        dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
      }
    };
    getQuestions();
  }, []);

  const updateQuestion = async (question: Question) => {
    try {
      return await new QuestionnaireClient().create(question);
    } catch (err) {
      throw err;
    }
  };

  const submit = async () => {
    try {
      // Update Questionnaire
      const questionsRes = data.data.questions.map((question: Question) => {
        updateQuestion(question);
      });
      // Update Questionnaire Header
      const header = { header: data.data.header };
      const questionHeaderRes = new QuestionnaireHeaderClient().update(header);
      await Promise.all(questionsRes);
      await Promise.all([questionHeaderRes]);

      dispatch(
        addNotification({
          title: "Success",
          description: "Your information has been updated.",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occured.",
            type: "error",
          })
        );
      }
    }
  };

  const clearChanges = () => {
    const getQuestions = async () => {
      try {
        const data = await client.get();
        setData({
          data: {
            questions: data,
          },
        });
        dispatch(
          addNotification({
            title: "Success",
            description: "Your changes were discarded.",
            type: "success",
          })
        );
      } catch (err) {
        dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
      }
    };
    getQuestions();
  };

  return (
    <Container>
      <PageHeading
        buttons={[
          { label: "Cancel", variations: { light: true }, onClick: clearChanges },
          { label: "Save", variations: { primary: true }, onClick: submit },
        ]}>
        Questionnaire
      </PageHeading>
      {data && <QuestionnaireTab data={data} setData={setData} />}
    </Container>
  );
};
export default QuestionnaireListPage;
