import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";

class BuildClient extends APIBaseClient {
  constructor() {
    super();
    this.baseEndpoint = "website";
  }

  public get = async () => {
    const user = await new AuthClient().getUser();
    const token = user.tokenPayload?.access_token;
    const res = await this.call("build", HTTP_METHOD.GET, {}, token);
    return res;
  };
}

export default BuildClient;
