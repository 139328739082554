import { GatsbyImage } from "gatsby-plugin-image";
import { ImageProps } from "./Image.d";

/**
 * A generic image component to simplify use of multiple image formats
 *
 * @component
 * @param {ImageProps} props
 * @param {string|IGatsbyImageData} props.src - Either a string for static image or Gatsby Image Data object for dynamic images
 * @param {string} props.alt - Alt text for the image.
 *
 * @returns <img />
 */
const Image: React.FC<ImageProps> = ({ src, alt, ...remainingProps }) => {
  switch (typeof src) {
    case "string":
      return <img src={src} alt={alt} {...remainingProps} />;

    default:
      return <GatsbyImage image={src} alt={alt} {...remainingProps} />;
  }
};

export default Image;
