import styled, { StyledComponent } from "@emotion/styled";
import { GatsbyLinkProps, Link } from "gatsby";
import React from "react";
import tw from "twin.macro";
import { Loader } from "../Icons";
import { ButtonLinkProps, ButtonProps } from "./Button.d";

const StyledButton: StyledComponent<ButtonProps> = styled.button(
  ({ block, primary, secondary, light, disabled, small }) => [
    tw`flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 `,
    primary &&
      tw`text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-500`,
    secondary &&
      tw`text-primary-600 bg-primary-100 hover:bg-primary-200 focus:ring-primary-500`,
    light &&
      tw`text-gray-700 bg-gray-100 hover:bg-gray-200 focus:ring-gray-500`,
    block && tw`w-full`,
    disabled && tw`!pointer-events-none !opacity-80`,
    small && tw`px-2.5 py-1.5 text-xs`,
  ]
);

export const ButtonLink: StyledComponent<GatsbyLinkProps<{}>> =
  StyledButton.withComponent(Link);

export const Button: React.FC<ButtonProps & { loading?: boolean }> = ({
  loading = false,
  children,
  ...remainingProps
}) => (
  <StyledButton {...remainingProps} disabled={loading}>
    {children}
    {loading && <Loader css={[tw`animate-spin text-white h-3 w-3 ml-3`]} />}
  </StyledButton>
);
