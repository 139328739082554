import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import tw from "twin.macro";

const Logo = ({ ...remainingProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 240"
      {...remainingProps}
    >
      <path
        d="m1449.54 481.559 199.04 1122.811c.6 3.38 3.46 5.91 6.9 6.08l295.83 14.48c4.74.23 8.48-4.01 7.65-8.69L1762.69 505.469c-8.7-54.348 13.05-84.797 60.89-84.797 13.28 0 27.58 3.008 38.79 6.976 5.53 1.961 10.9-3.199 9.38-8.867l-57.91-215.633c-.71-2.636-2.91-4.718-5.58-5.339-52.42-12.34-97.08-18.508-149.95-18.508-158.75 0-247.9 86.969-208.77 302.258M2291.88 634.559c-7.93 6.621-13.03 4.011-11.87-6.661 12.68-116.707 94.03-202.878 255.05-202.878 135.52 0 232.91 34.57 315.44 80.351 5.6 3.098 12.22-1.832 10.77-8.07l-66.69-243.031c-.47-2.051-1.73-3.782-3.55-4.829-78.47-44.66-203.38-70.14-351.65-70.14-297.93 0-476.23 171.781-476.23 443.597 0 339.254 254.41 606.722 615.41 606.722 245.71 0 376.2-128.29 376.2-306.62 0-197.898-187.02-343.59-450.14-343.59-86.68 0-166.2 17.852-212.74 55.149m1.97 109.472c-1.33-5.261 1.94-10.59 6.63-11.09 11.64-1.23 25.67-1.23 41.04-1.23 202.24 0 310.97 68.394 310.97 164.648 0 58.27-36.97 96.254-100.04 96.254-100.13 0-219.05-92.39-258.6-248.582"
        style={{
          fill: "#b1a99f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.13333 0 0 -.13333 0 240)"
      />
      <path
        d="m3321.87 706.211 162.48-291.93 218.42 280.207-161.01 291.567-219.89-279.844"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.13333 0 0 -.13333 0 240)"
      />
      <path
        d="M815.297 954.266C673.441 982.328 589.48 872.695 567.391 746.34c-24.188-138.328 18.621-262.918 143.855-289.828 108.055-23.223 214.074 39.398 247.906 207.929 27.641 137.676-20.316 265.383-143.855 289.825Zm527.923-528.957-57.9-215.629c-.71-2.641-2.92-4.719-5.58-5.352-43.07-10.129-81.12-15.867-122.56-17.68-8.13-.488-16.12-.828-23.7-.828l-1.44.071-2.26-.071c-100.53 0-173.01 35.039-202.811 115.309-2.547 1.23-5.633 1.121-7.985-1.07-81.472-75.899-185.132-120.758-303.046-120.758-216.043 0-383.809 191.347-383.809 467.519 0 343.59 231.476 582.81 494.695 582.81 119.965 0 223.985-45.37 293.016-138h.01c.93-1.22 2.48-1.75 4.26-1.79 2.34-.05 4.63 1.34 5 3.41l15.42 86.98a7.4 7.4 0 0 0 7.29 6.11h296.15c4.62 0 8.11-4.18 7.28-8.73L1234.17 512c-8.21-51.289 10.99-80.941 53.33-84.168 2.72-.191 5.28-.551 8.18-.57 7.87.047 15.83 1.289 23.51 3 3.83.957 7.69 1.828 11.41 3.297l1.6.16 1.5.402c5.61 1.981 11.06-3.07 9.52-8.812M3935.12 416.43c-55.49 0-95.41 23.922-137.59 106.55l-306.12 554.31-.21.42c-57.83 115.71-124.26 154.18-241.8 154.18-32.45 0-78.25-6.4-127.2-16.46a7.572 7.572 0 0 1-5.77-5.42l-56.96-211.494c-1.1-4.071 1.98-8.071 6.19-8.059l21.74.051c48.82 0 90.99-21.742 133.15-102.199l306.36-550.438c58.17-112.82 110.96-162.801 244-162.801 44.37 0 90.64 8.75 141.94 18.41 2.84.54 5.16 2.68 5.88 5.481l55.45 214.488c1.19 4.59-2.82 8.832-7.46 7.871-10.07-2.07-24.98-4.89-31.6-4.89"
        style={{
          fill: "#b1a99f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.13333 0 0 -.13333 0 240)"
      />
      <path
        d="M3312.93 201.25h-360.82c-9.81 0-16.75 10.988-12.02 19.09l381.78 485.871 162.48-291.93-161.89-207.691c-1.93-3.328-5.49-5.34-9.53-5.34m389.87 493.27-161.02 291.558 168.42 214.342c1.95 3.3 5.5 5.27 9.51 5.27h354.63c9.83 0 16.76-11.05 11.99-19.14L3702.8 694.52"
        style={{
          fill: "#E14B46",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.13333 0 0 -.13333 0 240)"
      />
      <path
        d="m3484.35 414.281-162.48 291.93.02.019 162.48-291.929-.02-.02"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.13333 0 0 -.13333 0 240)"
      />
      <path
        d="m3484.35 414.281-162.48 291.93.02.019 162.48-291.929-.02-.02"
        style={{
          fill: "#E14B46",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.13333 0 0 -.13333 0 240)"
      />
      <path
        d="M3484.37 414.301 3321.89 706.23l219.89 279.848L3702.8 694.52l-218.43-280.219"
        style={{
          fill: "#b1a99f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.13333 0 0 -.13333 0 240)"
      />
      <path
        d="M3484.37 414.301 3321.89 706.23l219.89 279.848L3702.8 694.52l-218.43-280.219"
        style={{
          fill: "#E14B46",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.13333 0 0 -.13333 0 240)"
      />
    </svg>
  );
};

export default Logo;
