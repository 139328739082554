import React, { useEffect, useState, useContext } from "react";
import Container from "../../Container/Container";
import { Input } from "../../Form";
import PageHeading from "../../PageHeading/PageHeading";
import tw from "twin.macro";
import AuthClient from "../../../clients/Auth";
import { AuthResponse } from "../../../clients/Auth.d";
import { NotificationsContext } from "../../../context/Notifications";
import { addNotification } from "../../../context/Notifications/actions";
import { User } from "../../../global";
import { Button } from "../../Button";

const Profile = () => {
  const client = new AuthClient();
  const [user, setUser] = useState<AuthResponse>();
  const [notifications, dispatch] = useContext(NotificationsContext);
  const [wasEmailChanged, setWasEmailChanged] = useState(false);
  const [initialData, setInitialData] = useState<User>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);

  const updateField = (key: string, value: string) => {
    const prevUserDetails = user.user;
    if (key === "email") {
      if (value !== initialData?.email) {
        setWasEmailChanged(true);
      } else {
        setWasEmailChanged(false);
      }
    }
    const updatedUser = {
      ...user,
      user: {
        ...prevUserDetails,
        [key]: value,
      },
    };
    setUser(updatedUser);
  };

  const validateEmail = (email: string) => {
    return /^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/.test(email);
  };

  const validatePassword = (password: string, confirmation: string) => {
    return password === confirmation;
  };

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValid = validateEmail(user.user?.email);
    if (isValid === false) {
      dispatch(
        addNotification({
          title: "Error",
          description: "Please use a valid email.",
          type: "error",
        })
      );
      return;
    }

    try {
      const res = await client.update(user);
      if (wasEmailChanged) {
        const emailRes = await client.updateEmail(user);
      }
      dispatch(
        addNotification({
          title: "Success",
          description: "Your information has been updated!",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description:
              "Unable to update your profile information. Please try again.",
            type: "error",
          })
        );
      }
    }
  };

  const handlePasswordReset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSubmitting(true);
    const isValid = validatePassword(password, passwordConfirmation);
    if (isValid === false) {
      dispatch(
        addNotification({
          title: "Error",
          description: "Please make sure passwords match and try again.",
          type: "error",
        })
      );
      return;
    }

    try {
      const res = await client.resetPassword(user, user.user.userID, password);
      dispatch(
        addNotification({
          title: "Success",
          description: "Your password was updated!",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description:
              "Unable to update your profile information. Please try again.",
            type: "error",
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  const handleCancel = () => {
    window.location.reload();
  };

  useEffect(() => {
    const getUser = async () => {
      const data = await client.getUser();
      setUser(data);
      setInitialData(data);
    };
    getUser();
  }, []);

  return (
    <Container>
      <PageHeading
        buttons={[
          {
            label: "Cancel",
            onClick: handleCancel,
            variations: { light: true },
          },
          {
            label: "Save",
            onClick: handleSubmitForm,
            variations: { primary: true },
          },
        ]}
      >
        Profile
      </PageHeading>
      <form css={tw`space-y-6 w-1/2`}>
        <Input
          label="First Name"
          name="first-name"
          value={user?.user?.firstName ? user.user.firstName : ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateField("firstName", e.target.value)
          }
        />
        <Input
          label="Last Name"
          name="last-name"
          value={user?.user?.lastName ? user.user.lastName : ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateField("lastName", e.target.value)
          }
        />
        <Input
          label="Email"
          name="email"
          value={user?.user?.email ? user.user.email : ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateField("email", e.target.value)
          }
        />
        <hr />
        <Input
          label="Password"
          name="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
        <Input
          label="Password Confirmation"
          name="passwordConfirmation"
          type="password"
          value={passwordConfirmation}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPasswordConfirmation(e.target.value)
          }
        />
        <Button onClick={handlePasswordReset} primary loading={isSubmitting}>
          Reset Password
        </Button>
      </form>
    </Container>
  );
};

export default Profile;
