import React, { useState } from "react";
import tw from "twin.macro";
import { Button } from "../Button";
import ImageSelector from "../Form/ImageSelector";
import { MediaGallery } from "../MediaGallery";
import { Modal } from "../Modal";

const MediaGalleryPage = () => {
  const [open, setOpen] = useState(true);
  const [selectedImage, setSelectedImage] = useState("true");
  return (
    <>
      <ImageSelector label="Image" value={null} />
    </>
  );
};

export default MediaGalleryPage;
