import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { Sponsor } from "../../../../clients/Events.d";
import SponsorDetailClient from "../../../../clients/SponsorDetail";
import Container from "../../../Container/Container";
import { Input } from "../../../Form";
import ImageSelector from "../../../Form/ImageSelector";
import SectionHeading from "../../../SectionHeading/SectionHeading";
import { Table, TBody, TD, TH, THead, TR } from "../../../Table/TableElements";
import Toggle from "../../../Toggle/Toggle";

const JobSponsorsTab: React.FC = ({ data, setData }) => {
  const { sponsors } = data.data;
  const [sponsorDetails, setSponsorDetails] = useState(null);

  const handleUpdateSponsor = (key: string, value: string, sponsor: Sponsor) => {
    const updatedSponsor = {
      ...sponsor,
      [key]: value,
    };
    const index = sponsors.findIndex((s: Sponsor) => s.sponsorID === sponsor.sponsorID);
    const updatedSponsors = [...sponsors];
    updatedSponsors[index] = updatedSponsor;
    const newData = {
      ...data,
      data: {
        ...data.data,
        sponsors: updatedSponsors,
      },
    };
    setData(newData);
  };

  useEffect(() => {
    if (!data) return;
    const getJobSponsorDetails = async () => {
      const details = await new SponsorDetailClient().get(data.eventConfigID);
      setSponsorDetails(details);
    };
    getJobSponsorDetails();
  }, []);

  return (
    <Container>
      {/* <pre>{JSON.stringify(sponsors, null, 2)}</pre> */}
      <Table>
        <THead>
          <TR>
            <TH>ID</TH>
            <TH>Name</TH>
            <TH>Message</TH>
            <TH>URL</TH>
            <TH>Email</TH>
            <TH>Logo</TH>
            <TH>Hiring?</TH>
          </TR>
        </THead>
        <TBody>
          {sponsors?.map((sponsor: Sponsor) => (
            <TR>
              <TD css={tw`w-36 md:w-max`}>{sponsor.sponsorID}</TD>
              <TD css={[tw`font-medium`]}>
                <Input
                  name={sponsor.name}
                  value={sponsor.name}
                  onChange={(e) => handleUpdateSponsor("name", e.target.value, sponsor)}
                  label=""
                  css={tw`w-36 md:w-max`}
                  maxLength={15}
                />
              </TD>
              <TD>
                <Input
                  name={sponsor.booth}
                  value={sponsor.booth}
                  onChange={(e) => handleUpdateSponsor("booth", e.target.value, sponsor)}
                  label=""
                  css={tw`w-36 md:w-max`}
                  maxLength={20}
                />
              </TD>
              <TD>
                <Input
                  name={sponsor.url}
                  value={sponsor.url}
                  onChange={(e) => handleUpdateSponsor("url", e.target.value, sponsor)}
                  label=""
                  css={tw`w-36 md:w-max`}
                />
              </TD>
              <TD>
                <Input
                  name={sponsor.email}
                  value={sponsor.email}
                  onChange={(e) => handleUpdateSponsor("email", e.target.value, sponsor)}
                  label=""
                  css={tw`w-36 md:w-max`}
                />
              </TD>
              <TD>
                <ImageSelector
                  eventConfigID={data.eventConfigID}
                  initialCollection='Sponsor Logos'
                  value={sponsor.logo}
                  css={tw`max-w-[14rem]`}
                  onUpdate={(file: string) => handleUpdateSponsor("logo", file, sponsor)}
                />
              </TD>
              <TD>
                <Toggle 
                  enabled={sponsor.hiring === "Yes"}
                  label=''
                  onChange={(isChecked: boolean) => handleUpdateSponsor("hiring", isChecked ? 'Yes' : 'No', sponsor)} />
              </TD>
            </TR>
          ))}
        </TBody>
      </Table>
      <SectionHeading css={[tw`pt-5`]}>Job Sponsors Details</SectionHeading>
      <section css={tw`grid gap-4 mb-16`}>
        <Table css={tw`max-h-[45rem]`}>
          <THead css={tw`sticky top-0`}>
            <TH>Job ID</TH>
            <TH>Job Category</TH>
            <TH>Industry</TH>
            <TH>Job Level</TH>
            <TH>Sponsors</TH>
          </THead>
          <TBody>
            {sponsorDetails?.map((job) => {
              return (
                <TR>
                  <TD css={tw`max-w-xs truncate hover:whitespace-normal hover:overflow-visible`}>
                    {job.jobID}
                  </TD>
                  <TD css={tw`max-w-xs truncate hover:whitespace-normal hover:overflow-visible`}>
                    {job.jobCategory}
                  </TD>
                  <TD css={tw`max-w-xs truncate hover:whitespace-normal hover:overflow-visible`}>
                    {job.industry}
                  </TD>
                  <TD css={tw`max-w-xs truncate hover:whitespace-normal hover:overflow-visible`}>
                    {job.jobLevel}
                  </TD>
                  <TD css={tw`max-w-xs truncate hover:whitespace-normal hover:overflow-visible`}>
                    {job.sponsorIDs}
                  </TD>
                </TR>
              );
            })}
          </TBody>
        </Table>
      </section>
    </Container>
  );
};

export default JobSponsorsTab;
