import React, { useEffect, useState } from 'react';
import { EditorState, ContentState } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css';
import tw from 'twin.macro';
import { RichTextEditorProps } from './Form';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const RichTextEditor: React.FC<RichTextEditorProps> = ({ value, onChange }) => {
    const [editorState, setEditorState] = useState<EditorState>();

    const handleChange = (input: any) => {
        setEditorState(input)
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        const hashtagConfig = {
            trigger: '#',
            separator: ' ',
        }
        const html = draftToHtml(
            rawContentState, 
            hashtagConfig
          );
        onChange(html)
    }

    useEffect(() => {
        const blocksFromHtml = htmlToDraft(value)
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState))
    }, [])

    // Toolbar options: https://jpuri.github.io/react-draft-wysiwyg/#:~:text=/%3E-,toolbar,-toolbar%20property%20provides 
    return (      
        <div 
            css={[
                tw`relative shadow-sm w-full border border-gray-300 rounded-md resize-y h-96 overflow-y-scroll sm:text-sm`,
                tw`focus-within:ring-primary-500 focus-within:ring-2`
            ]}
        >      
            <Editor
                editorState={editorState}
                toolbar={{
                    options: ['inline', 'blockType', 'list', 'textAlign', 'fontSize', 'link', 'colorPicker', 'history'],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                }}
                onEditorStateChange={handleChange}
                editorClassName="rich-text-editor"
                toolbarClassName="rich-text-toolbar"
            />
        </div>
    )
}

export default RichTextEditor
