import React from "react";
import tw from "twin.macro";
import { Button } from "../Button";
import { ButtonLink } from "../Button/Button";
import { PageHeadingProps } from "./PageHeading.d";

const PageHeading: React.FC<PageHeadingProps> = ({ buttons, children }) => {
  return (
    <div  css={[tw`md:flex md:items-center md:justify-between mb-6 mt-4`]}>
      <div css={[tw`flex-1 min-w-0`]}>
        <h2
          css={[
            tw`text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate`,
          ]}
        >
          {children}
        </h2>
      </div>
      {buttons && (
        <div css={[tw`mt-4 flex md:mt-0 md:ml-4 gap-4`]}>
          {buttons.map(({ label, link, onClick, variations, disabled }, i: number) => {
            if (onClick) {
              return (
                <Button key={label + i} {...variations} onClick={onClick} disabled={disabled} loading={disabled}>
                  {label}
                </Button>
              );
            }
            return (
              <ButtonLink key={label + i} {...variations} to={link}>
                {label}
              </ButtonLink>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PageHeading;
