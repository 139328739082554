import React, { useEffect, useState, useContext } from "react";
import tw from "twin.macro";
import OrganizationsClient from "../../../clients/Organizations";
import { NotificationsContext } from "../../../context/Notifications";
import { addNotification } from "../../../context/Notifications/actions";
import Container from "../../Container/Container";
import Select from "../../Form/Select";
import PageHeading from "../../PageHeading/PageHeading";
import { Table, THead, TR, TH, TD } from "../../Table/TableElements";
import { OrganizationsEditPageProps } from "./Organization.d";
import { Input } from "../../Form";
import { navigate } from "gatsby"

const OrganizationsEditPage: React.FC<OrganizationsEditPageProps> = ({
  organizationID,
}) => {
  const [data, setData] = useState({
    orgName: "",
    statusCode: "Active",
    pin: ""
  });
  const [notifications, dispatch] = useContext(NotificationsContext)
  const date = data.hasOwnProperty("creationDate") ? new Date(data.creationDate.replace('T', ' ')).toLocaleDateString() : new Date().toLocaleDateString()

  useEffect(() => {
    if(!organizationID) return
    const getOrganizationData = async () => {
      try {
        const client = new OrganizationsClient();
        const data = await client.getOne(organizationID);
        setData(data);
      } catch(err) {
        dispatch(addNotification({title: "Error", description: err?.message, type: "error"}))
      }
    };
    getOrganizationData();

  }, []);

  const updateField = (key: string, value:any) => {
    if (key === 'pin') {
      const reg = new RegExp('^[0-9]+$');
      // console.log(value, Number.parseInt(value), reg.test(value), value.length)
      if (value === '' || (value.length <= 4 && reg.test(value) && (Number.parseInt(value) >= 0 && Number.parseInt(value) <= 9999))) {
        const newData = {
          ...data,
          [key]: value,
        };
        setData(newData); 
      }
    } else {
      const newData = {
        ...data,
        [key]: value,
      };
      setData(newData);
    }
  }

  const handleSubmit = async () => {
    try {
      const client = new OrganizationsClient()
      if(data.hasOwnProperty("organizationID")) {
        const res = await client.update(data)
        dispatch(addNotification({title: "Success", description: "Your organization has successfully been updated.", type: "success"}))
      } else {
        const res = await client.create(data)
        dispatch(addNotification({title: "Success", description: "Your organization has successfully been created.", type: "success"}))
      }
      navigate("/organizations")
    } catch(err) {
      // console.log(err)
      dispatch(addNotification({title: "Error", description: err?.message, type: "error"}))
    }
  }

  return (
    <Container>
      <PageHeading
        buttons={[
          {
            label: "Cancel",
            variations: { light: true },
            link: `/organizations`,
          },
          { 
            label: "Save", 
            variations: { primary: true },
            onClick: handleSubmit
          },
        ]}
      >
        Edit {data?.orgName}
      </PageHeading>
      <Table>
        <THead>
          <TR>
            <TH>Organization</TH>
            <TH>Status</TH>
            <TH>Created</TH>
            <TH>PIN</TH>
          </TR>
        </THead>
          <TR>
            <TD css={[tw`font-medium`]}>
              <Input 
                label=""
                value={data?.orgName}
                name="orgName"
                onChange={(e) => updateField(e.target.name, e.target.value)}
              />
            </TD>
            <TD>
              <Select 
                label="" 
                name="statusCode" 
                value={data?.statusCode} 
                onChange={(e) => updateField(e.target.name, e.target.value)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Select>
            </TD>
            <TD>{date}</TD>
            <TD>
              <Input
                value={data?.pin}
                label=""
                name="pin"
                onChange={(e) => updateField(e.target.name, e.target.value)}
              />
            </TD>
          </TR>
      </Table>
    </Container>
  );
};

export default OrganizationsEditPage;
