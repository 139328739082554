import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { AppFrame } from "./Layout";
import { UserContext } from "../context/User/UserContext";
import { User } from "../global";
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { isLoggedIn, checkSessionExpired, user } = useContext(UserContext);
  const [shouldRender, setShouldRender] = useState(true);

  const isAuthorized = (user: User, pathname: string): boolean => {
    if (user.role === 'Admin') {
      return true;
    } else if (user.role === 'Event Admin') {
      if (pathname.includes('/organizations') || pathname.includes('/questionnaire') || pathname.includes('/users')) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  useEffect(() => {
    const sessionIsExpired = checkSessionExpired();
    if (
      (!isLoggedIn || sessionIsExpired) &&
      location.pathname !== `/login` &&
      typeof window !== "undefined"
    ) {
      navigate("/login");
      setShouldRender(false);
    }

    if (!isAuthorized(user?.user, location.pathname)) {
      navigate("/login");
      setShouldRender(false);
    }
  }, []);

  if (!shouldRender) return null;

  return (
    <AppFrame>
      <Component {...rest} />
    </AppFrame>
  );
};
export default PrivateRoute;
