import React from "react";
import tw from "twin.macro";
import { knownBadgeimeoutValues } from "../../../../helpers";
import Container from "../../../Container/Container";
import { Input } from "../../../Form";
import Select from "../../../Form/Select";
import Textarea from "../../../Form/Textarea";

const KnownBadgeMessageTab: React.FC = ({ data, updateFormValue }) => {
  const { knownBadgeTitle, knownBadgeMessage, knownBadgeTimeout } = data.data;
  return (
    <Container>
      <div css={[tw`grid gap-4`]}>
        <Input
          label="Known Badge Message Title"
          value={knownBadgeTitle}
          name="knownBadgeTitle"
          onChange={(e) => updateFormValue(e.target.name, e.target.value)}
          maxLength={30}
        />
        <Textarea
          label="Known Badge Message Body"
          value={knownBadgeMessage}
          name="knownBadgeMessage"
          onChange={(e) => updateFormValue(e.target.name, e.target.value)}
          maxLength={60}
        />
        <Select
          label="Known Badge Timeout"
          value={knownBadgeTimeout}
          name="knownBadgeTimeout"
          onChange={(e) => updateFormValue(e.target.name, e.target.value)}
        >
          <option value="None">None</option>
          {knownBadgeimeoutValues.map((value) => (value <= 3600000 ? <option value={value}>{value / 60000} minutes</option> : <option value={value}>{value / 60000 / 60} hours</option>))}
        </Select>
      </div>
    </Container>
  );
};

export default KnownBadgeMessageTab;
