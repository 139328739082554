import React, { useState } from "react";
import tw from "twin.macro";
import { Button } from "../Button";
import { MediaGallery } from "../MediaGallery";
import { Modal } from "../Modal";

const MediaGalleryModal = ({ eventConfigID, initialCollection, onSelect }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      trigger={
        <Button primary small>
          Choose File
        </Button>
      }
      css={tw`overflow-y-scroll`}
    >
      <MediaGallery
        eventConfigID={eventConfigID}
        initialCollection={initialCollection}
        selectImage={(selectedImage) => {
          onSelect(selectedImage);
          setOpen(false);
        }}
      />
    </Modal>
  );
};

export default MediaGalleryModal;
