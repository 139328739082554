import React from "react";

const usePagination = (
    data: [], 
    itemsPerPage: number, 
    currentPage: number = 1, 
    setCurrentPage: (page: number) => any, 
    ) => {
    const maxPage = Math.ceil(data.length / itemsPerPage);
 
    const getCurrentData = () => {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    }

    const handleNext = () => {
        const nextPage = Math.min(currentPage + 1, maxPage)
        setCurrentPage(nextPage);
    }

    const handlePrev = () => {
        const prevPage = Math.max(currentPage - 1, 1)
        setCurrentPage(prevPage);
    }

    const handleJumpTo = (page: number) => {
        const pageNumber = Math.max(1, page);
        setCurrentPage(Math.min(pageNumber, maxPage));
    }

  return { handleNext, handlePrev, handleJumpTo, getCurrentData, currentPage, maxPage };
}

export default usePagination