import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";
import EventConfigsClient from "./EventConfigs";
import { Event } from "./Events.d";

class EventsClient extends APIBaseClient {
  constructor() {
    super();
    this.baseEndpoint = "events";
  }
  private loggedInUser = new AuthClient().getUser();
  private token = this.loggedInUser?.tokenPayload?.access_token;

  public get = async () => {
    const res = await this.call("", HTTP_METHOD.GET, {}, this.token);
    return await res;
  };

  public getOne = async (id: string) => {
    try {
      const event = await this.call(id, HTTP_METHOD.GET, {}, this.token);
      return event;
    } catch (err) {
      throw err;
    }
  };

  public update = async (id: string, data: Event) => {
    try {
      const user = await new AuthClient().getUser();
      const token = user.tokenPayload?.access_token;
      const event = await this.call(id, HTTP_METHOD.PUT, data, this.token);
      return event;
    } catch (err) {
      throw err;
    }
  };

  public delete = async (id: string) => {
    try {
      const event = await this.call(id, HTTP_METHOD.DELETE, {}, this.token);
      return event;
    } catch (err) {
      throw err;
    }
  };

  public getActiveEventConfig = async (id: string) => {
    try {
      const activeEventConfigInfo = await this.call(
        `${id}/active-event-config-info`,
        HTTP_METHOD.GET,
        this.token
      );
      const configId = activeEventConfigInfo?.eventConfigID;
      const config = await new EventConfigsClient().getOne(configId);
      return config;
    } catch (err) {
      throw err;
    }
  };
}

export default EventsClient;
