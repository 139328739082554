import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";

class EventsAdmin extends APIBaseClient {
  constructor() {
    super();
    this.baseEndpoint = "admin";
  }

  private loggedInUser = new AuthClient().getUser();
  private token = this.loggedInUser?.tokenPayload?.access_token;

  public get = async () => {
    const res = await this.call("events", HTTP_METHOD.GET, {}, this.token);
    return await res;
  };
}

export default EventsAdmin;
