import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";
import { Preview } from "./Preview.d";

class PreviewClient extends APIBaseClient {
  constructor() {
    super();
    this.baseEndpoint = "themes";
  }
  private loggedInUser = new AuthClient().getUser();
  private token = this.loggedInUser.tokenPayload?.access_token;

  public get = async (
    themeID: string,
    collection?: string,
    statusCode?: string
  ): Promise<Preview[]> => {
    let url = `${themeID}/previews`;
    if (collection && !statusCode) {
      url = `${url}?Collection=${collection}`;
    }
    if (!collection && statusCode) {
      url = `${url}?StatusCode=${statusCode}`;
    }
    if (collection && statusCode) {
      url = `${url}?Collection=${collection}&StatusCode=${statusCode}`;
    }
    const res = await this.call(url, HTTP_METHOD.GET);
    return await res;
  };

  public getCollections = async (themeID: string): Promise<string[]> => {
    const res = await this.call(
      `${themeID}/preview-collections`,
      HTTP_METHOD.GET
    );
    return await res;
  };

  public create = async (
    themeID: string,
    file: Blob,
    collection: string
  ): Promise<Preview[]> => {
    const formData = new FormData();

    formData.append("files", file);

    const res = await fetch(
      `${this.rootUrl}/${this.baseEndpoint}/${themeID}/previews?Collection=${collection}`,
      {
        method: HTTP_METHOD.POST,
        body: formData,
        // headers: {
        //     "Authorization": "Bearer " + this.token
        // }
      }
    );
    return res.json();
  };

  public update = async (themeID: string, previews: Array<Preview>) => {
    return await this.call(`${themeID}/previews`, HTTP_METHOD.PUT, previews);
  };

  public delete = async (themeID: string, previews: Array<Preview>) => {
    return await this.call(`${themeID}/previews`, HTTP_METHOD.DELETE, previews);
  };
}

export default PreviewClient;
