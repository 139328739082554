import React from "react";
import tw from "twin.macro";

const SectionHeading: React.FC = ({ children, ...remainingProps }) => {
  return (
    <div css={[tw`pb-2 border-b border-gray-200 mb-4`]} {...remainingProps}>
      <h3 css={[tw`text-lg leading-6 font-medium text-gray-900`]}>{children}</h3>
    </div>
  );
};

export default SectionHeading;
