import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";
import { SchoolLogo } from "./School.d"

class SchoolClient extends APIBaseClient {
    constructor() {
        super()
        this.baseEndpoint = 'schools'
    }

    public updateLogo = async (school: SchoolLogo) => {
        const user = await new AuthClient().getUser()
        const token = user.tokenPayload?.access_token
        const res = await this.call('logo', HTTP_METHOD.PUT, school, token);
        return res
    }
}

export default SchoolClient