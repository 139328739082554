import {
  CalendarIcon,
  LibraryIcon,
  TemplateIcon,
  QuestionMarkCircleIcon,
  UsersIcon,
  UserIcon,
  ColorSwatchIcon
} from "@heroicons/react/outline";
import { User } from "../global";

const useNavigationData = (user: User, currentPage: string) => {
  let links = [
    {
      name: "Dashboard",
      href: "/",
      icon: TemplateIcon,
      current: currentPage === "/",
    },
    {
      name: "Events",
      href: "/events",
      icon: CalendarIcon,
      current: currentPage.includes("/events"),
    },
    {
      name: "Themes",
      href: "/themes",
      icon: ColorSwatchIcon,
      current: currentPage.includes("/themes"),
    },
    {
      name: "Organizations",
      href: "/organizations",
      icon: LibraryIcon,
      current: currentPage.includes("/organizations"),
    },
    {
      name: "Questionnaire",
      href: "/questionnaire",
      icon: QuestionMarkCircleIcon,
      current: currentPage.includes("/questionnaire"),
    },
    {
      name: "User Management",
      href: "/users",
      icon: UsersIcon,
      current: currentPage.includes("/users"),
    },
    {
      name: "Your Profile",
      href: "/profile",
      icon: UserIcon,
      current: currentPage.includes("/profile")
    }
  ];

  // if (user?.role != 'Admin') {
  //   links = links.filter(l => !l.href.includes('/users') && !l.href.includes('/organizations') && !l.href.includes('/questionnaire'));
  // }

  return links;
};

export default useNavigationData;
