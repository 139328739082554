import tw from "twin.macro"
import { css } from "@emotion/react"

const customGlobalStyles = css`
    html {
        ${tw`h-full bg-white`}
    }
    
    body {
        ${tw`h-full`}
    }
    
    #___gatsby,
    #gatsby-focus-wrapper,
    #gatsby-focus-wrapper > div {
        ${tw`h-full`}
    }

    h1 {
        ${tw`text-4xl`}
        ${tw`font-bold`}
    }
    
    h2 {
      ${tw`text-3xl`}
      ${tw`font-bold`}
    }
    
    h3 {
      ${tw`text-2xl`}
      ${tw`font-bold`}
    }
    
    h4 {
      ${tw`text-xl`}
      ${tw`font-semibold`}
    }
    
    h5 {
      ${tw`text-lg`}
      ${tw`font-semibold`}
    }
    
    h6 {
      ${tw`text-base`}
      ${tw`font-semibold`}
    }
    
    .rich-text-editor {
      ${tw`px-2`}
    }
    
    .rich-text-toolbar {
      ${tw`sticky top-0 bg-white z-10`}
    }
`

export default customGlobalStyles