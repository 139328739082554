import React, { useState } from 'react'
import tw from 'twin.macro'
import { TooltipProps } from './Tooltip.d'

const Tooltip: React.FC<TooltipProps> = ({ text, children, ...remainingProps }) => {
    const [show, setShow] = useState(false)

    return (
        <div css={tw`relative`}>
            <div
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                {...remainingProps}
            >
                {children}
            </div>
            <div css={[tw`absolute p-4 shadow rounded-lg hidden bg-white text-xs z-50`, show && tw`block`]}>
                {text}
            </div>
        </div>
    )
}

export default Tooltip
