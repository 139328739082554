import { UploadIcon } from "@heroicons/react/solid";
import moment from "moment";
import React, { useEffect, useRef, useState, useContext } from "react";
import tw from "twin.macro";
import AssetClient from "../../clients/Asset";
import { Asset } from "../../clients/Asset.d";
import EventConfigsClient from "../../clients/EventConfigs";
import EventsClient from "../../clients/Events";
import { Event } from "../../clients/Events.d";
import { NotificationsContext } from "../../context/Notifications";
import { addNotification } from "../../context/Notifications/actions";
import { Button } from "../Button";
import { Input } from "../Form";
import Select from "../Form/Select";
import MediaList from "./MediaList";
import ProgressBar from "./ProgressBar";

const MediaGallery = ({ eventConfigID, initialCollection, selectImage }) => {
  const [assets, setAssets] = useState<Asset[]>(null);
  const [filteredAssets, setFilteredAssets] = useState<Asset[]>(null);
  const [collections, setCollections] = useState<string[]>(null);
  const [activeCollection, setActiveCollection] = useState<string>(null);
  const [activeStatusCode, setActiveStatusCode] = useState<string>("Active");
  const [searchTerms, setSearchTerms] = useState<string>(null);
  const [selectedAsset, setSelectedAsset] = useState<Asset>(null);
  const fileFieldRef = useRef();
  const [notifications, dispatch] = useContext(NotificationsContext);
  const [uploadProgress, setUploadProgress] = useState<number>(-1);
  const [uploadingFile, setUploadingFile] = useState<string>(null);
  const [uploadTimeRemaining, setUploadTimeRemaining] = useState<number>(0);

  useEffect(() => {
    if (!assets) return;
    const newFilteredAssets = assets.filter(
      (asset) =>
        asset.collection &&
        asset.collection.toLowerCase() === activeCollection.toLowerCase() &&
        asset.statusCode === activeStatusCode
    );
    setFilteredAssets(newFilteredAssets);
  }, [activeCollection, activeStatusCode]);

  useEffect(() => {
    if (!assets) return;

    if (!searchTerms) {
      setFilteredAssets(assets);
      return;
    }
    const lowerSearch = searchTerms.toLowerCase();
    const newFilteredAssets = assets.filter(
      (asset) =>
        asset.fileName.toLowerCase().includes(lowerSearch) ||
        asset.originalFileName.toLowerCase().includes(lowerSearch)
    );
    setFilteredAssets(newFilteredAssets);
  }, [searchTerms]);

  useEffect(() => {
    if (!eventConfigID) return;

    const getAssetsAndCollections = async () => {
      const client = new AssetClient();
      let assets = await client.get(eventConfigID, null, "Active");
      let collections = await client.getCollections(eventConfigID);
      let activeAssets = assets;
      let inactiveAssets = await client.get(eventConfigID, null, "Inactive");
      assets.push(...inactiveAssets);
      if (initialCollection) {
        setActiveCollection(initialCollection);
        activeAssets = activeAssets?.filter(
          (a) => a.collection === initialCollection
        );
      } else {
        setActiveCollection("");
      }

      setAssets(assets);
      setFilteredAssets(activeAssets);
      collections = collections.filter(
        (c) => c !== "Reference Data" && c !== "States"
      );
      const defaultCollections = [
        "Default Screen Assets",
        "Misc",
        "COL Age Group Assets (Cost of Living)",
        "Greeter Icons",
        "Job Icons",
        "School Logos",
        "Sponsor Logos",
        "Videos",
        "Footer Assets",
      ];
      collections = [...new Set([...collections, ...defaultCollections])];
      collections.sort((a: string, b: string) => {
        const upA = a.toUpperCase();
        const upB = b.toUpperCase();
        if (upA < upB) {
          return -1;
        }
        if (upA > upB) {
          return 1;
        }
        return 0;
      });
      setCollections(collections);
    };
    getAssetsAndCollections();
  }, [eventConfigID, initialCollection]);

  const handleUploadClick = () => {
    if (!fileFieldRef?.current) return;
    fileFieldRef.current.click();
  };

  const handleUpload = async (e) => {
    if (e.target.files.length < 1) return;
    let newAssets = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      // console.log("handleUpload", { file });
      const newAsset = await uploadFile(file);
      if (newAsset.length > 0) {
        newAssets.push(newAsset[0]);
      }
    }
    let newFilteredAssets = [...filteredAssets, ...newAssets];
    newFilteredAssets = newFilteredAssets.map((a, i) => {
      a.sortOrder = i
      return a;
    });
    newFilteredAssets.sort((a, b) => a.sortOrder - b.sortOrder);
    await new AssetClient().update(eventConfigID, newFilteredAssets);
    setFilteredAssets(newFilteredAssets);
    setAssets([...assets, ...newAssets]);
    setSelectedAsset(newFilteredAssets[newFilteredAssets.length - 1]);
  };

  const uploadFile = async (file: File) => {
    setUploadProgress(0);
    setUploadingFile(file.name);
    var assets: any[] = [];
    // create array to store the buffer chunks
    var FileChunk = [];
    // set up other initial vars
    var MaxFileSizeMB = 1;
    var BufferChunkSize = MaxFileSizeMB * (1024 * 1024);
    var ReadBuffer_Size = 1024;
    var FileStreamPos = 0;
    // set the initial chunk length
    var EndPos = BufferChunkSize;
    var Size = file.size;

    // add to the FileChunk array until we get to the end of the file
    while (FileStreamPos < Size) {
      // "slice" the file from the starting position/offset, to  the required length
      FileChunk.push(file.slice(FileStreamPos, EndPos));
      FileStreamPos = EndPos; // jump by the amount read
      EndPos = FileStreamPos + BufferChunkSize; // set next chunk length
    }
    // get total number of "files" we will be sending
    var TotalParts = FileChunk.length;
    var PartCount = 0;
    var chunkTimes = [];
    // loop through, pulling the first item from the array each time and sending it
    let chunk: Blob = null;
    while ((chunk = FileChunk.shift())) {
      var startTime = performance.now();
      PartCount++;
      // file name convention
      var FilePartName = file.name + ".part_" + PartCount + "." + TotalParts;
      // send the file
      const res = await uploadFileChunk(chunk, FilePartName);
      // const res = await delay(100 + 10 * Math.random());
      var endTime = performance.now();
      var chunkTime = endTime - startTime;
      chunkTimes.push(chunkTime);
      var avgChunkTime = chunkTimes.reduce((p, c) => p += c, 0) / chunkTimes.length;
      var timeRemaining = (TotalParts - PartCount) * avgChunkTime;
      setUploadTimeRemaining(timeRemaining);
      setUploadProgress(Math.ceil((PartCount / TotalParts) * 100));
      // console.log(`Chunk ${PartCount}/${TotalParts} took ${Math.ceil(chunkTime)} ms to upload... ${Math.floor(moment.duration(timeRemaining).asSeconds())} seconds remaining from avg chunk time of ${Math.ceil(avgChunkTime)} ms`);
      if (res.length > 0) {
        assets.push(res[0]);
        setUploadProgress(100);
      }
    }
    setUploadProgress(-1);
    setUploadingFile(null);
    setUploadTimeRemaining(0);
    return assets;
  };

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  const uploadFileChunk = async (chunk: Blob, fileName: string): Promise<Asset[]> => {
    // console.log('uploadFileChunk', { eventConfigID, chunk, activeCollection, fileName });
    const res = await new AssetClient().uploadChunk(eventConfigID, chunk, activeCollection, fileName);
    return res;
  };

  const handleUpdate = (key: string, value: any) => {
    const updatedAsset = {
      ...selectedAsset,
      [key]: value,
    };
    const index = filteredAssets.findIndex(
      (a) => a.assetID === selectedAsset.assetID
    );
    let newAssets = [...filteredAssets];
    newAssets[index] = updatedAsset;
    if (selectedAsset.collection !== updatedAsset.collection) {
      newAssets = newAssets.filter((a) => a.collection === activeCollection);
    }
    setFilteredAssets([...newAssets]);
    setSelectedAsset(updatedAsset);
    updateAssets([updatedAsset]);
  };

  const updateAssets = async (assets: Asset[]) => {
    try {
      const res = await new AssetClient().update(eventConfigID, assets);
      dispatch(
        addNotification({
          title: "Success",
          description: "Images have been updated.",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occured.",
            type: "error",
          })
        );
      }
    }
  };

  const pruneAndDelete = async () => {
    try {
      const res = await new AssetClient().prune(eventConfigID, true);
      const active: string[] = res.active;
      const newAssets = [...assets].filter((a: Asset) =>
        active.includes(a.assetID)
      );
      const selected = newAssets[0];
      setAssets([...newAssets]);
      setFilteredAssets(
        [...newAssets].filter(
          (a) =>
            a?.collection.toUpperCase() === selected?.collection.toUpperCase()
        )
      );
      setSelectedAsset(selected);
      dispatch(
        addNotification({
          title: "Success",
          description: "Images have been deleted.",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occured.",
            type: "error",
          })
        );
      }
    }
  };

  const deleteAssets = async (assets: Asset[]) => {
    try {
      const res = await new AssetClient().delete(eventConfigID, assets);
      const assetIDs = assets.map((a) => a.assetID);
      const newAssets = filteredAssets.filter(
        (a) => !assetIDs.includes(a.assetID)
      );
      setFilteredAssets([...newAssets]);
      setSelectedAsset(newAssets[0]);
      dispatch(
        addNotification({
          title: "Success",
          description: "Image has been deleted.",
          type: "success",
        })
      );
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occured.",
            type: "error",
          })
        );
      }
    }
  };

  return (
    <div css={[tw`flex flex-col h-full`]}>
      {/* <pre>{JSON.stringify(assets, null, 2)}</pre> */}
      <header css={[tw`grid grid-cols-12 gap-4 items-end py-4`]}>
        <Select
          label="Collection"
          value={activeCollection}
          onChange={(e) => setActiveCollection(e.target.value)}
          wrapperStyles={[tw`col-span-5 sm:col-span-4 lg:col-span-3`]}
        >
          {collections &&
            collections.map((collection) => (
              <option key={collection} value={collection}>
                {collection}
              </option>
            ))}
        </Select>
        <Select
          label="Status"
          value={activeStatusCode}
          onChange={(e) => setActiveStatusCode(e.target.value)}
          wrapperStyles={[tw`col-span-3 sm:col-span-3 lg:col-span-2`]}
        >
          <option value={"Active"}>Active</option>
          <option value={"Inactive"}>Inactive</option>
        </Select>
        <Input
          label="Search"
          value={searchTerms}
          onChange={(e) => setSearchTerms(e.target.value)}
          css={[tw`col-span-3 sm:col-span-4 lg:col-span-3`]}
        />
        <Button
          primary
          css={[tw`col-span-6 lg:col-span-2`]}
          onClick={handleUploadClick}
        >
          <UploadIcon css={[tw`h-4 mr-2`]} />
          Upload New
        </Button>
        <input
          type="file"
          css={[tw`hidden`]}
          ref={fileFieldRef}
          onChange={handleUpload}
          multiple
        />
        <Button
          secondary
          css={tw`col-span-3 lg:col-span-3 xl:col-span-2`}
          onClick={() => pruneAndDelete()}
        >
          Delete Inactive
        </Button>
      </header>
      {uploadProgress >= 0 && <ProgressBar bgcolor="#e14b46" progress={uploadProgress}  height={4} />}
      {uploadingFile && <p>Uploading {uploadingFile} | {Math.ceil(uploadTimeRemaining / 1000)} seconds remaining</p>}
      {filteredAssets && (
        <MediaList
          assets={filteredAssets}
          collections={collections}
          selectImage={selectImage}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          updateAssets={updateAssets}
          handleUpdate={handleUpdate}
          deleteAssets={deleteAssets}
        />
      )}
    </div>
  );
};

export default MediaGallery;
