import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import { Asset } from "./Asset.d";
import AuthClient from "./Auth";
import { EventConfigResponse } from "./Events.d";
import QuestionnaireClient from "./Questionnaire";
class EventConfigsClient extends APIBaseClient {
  constructor() {
    super();
    this.baseEndpoint = "event-configs";
  }
  private loggedInUser = new AuthClient().getUser();
  private token = this.loggedInUser.tokenPayload?.access_token;

  public getOne = async (id: string) => {
    const config = await this.call(id, HTTP_METHOD.GET);
    const sponsors = await this.call(`${id}/sponsors`, HTTP_METHOD.GET);
    const questions = await new QuestionnaireClient().get();
    const schools = await this.call(`${id}/schools`, HTTP_METHOD.GET);
    const jobs = await this.call(`${id}/jobs`, HTTP_METHOD.GET);
    const financialAid = await this.call(
      `${id}/financial-aid`,
      HTTP_METHOD.GET
    );
    const jobFinancialAid = await this.call(
      `${id}/job-financial-aid`,
      HTTP_METHOD.GET
    );
    return {
      ...config,
      data: {
        ...config.data,
        sponsors,
        schools,
        questions,
        jobs,
        financialAid,
        jobFinancialAid,
      },
    };
  };

  public update = async (id: string, data: EventConfigResponse) => {
    const update = await this.call(id, HTTP_METHOD.PUT, data, this.token);
    return update;
  };

  public uploadRefData = async (
    eventConfigID: string,
    file: Blob
  ): Promise<Asset> => {
    const formData = new FormData();

    formData.append("RefData", file);

    const res = await fetch(
      `${this.rootUrl}/${this.baseEndpoint}/${eventConfigID}/refdata-active-file-upload`,
      {
        method: HTTP_METHOD.POST,
        body: formData,
        headers: {
          "Authorization": "Bearer " + this.token
      }
      }
    );
    return res.json();
  };

  public updateJobs = async (id: string, isDryRun: boolean) => {
    const updateJobs = await this.call(`${id}/refdata-job-update?IsDryRun=${isDryRun}`, HTTP_METHOD.PUT, {}, this.token);
    return updateJobs;
  };

  public updateSchools = async (id: string, isDryRun: boolean) => {
    const updateSchools = await this.call(`${id}/refdata-school-update?IsDryRun=${isDryRun}`, HTTP_METHOD.PUT, {}, this.token);
    return updateSchools;
  };

  public updateCities = async (id: string, isDryRun: boolean) => {
    const updateCities = await this.call(`${id}/refdata-city-update?IsDryRun=${isDryRun}`, HTTP_METHOD.PUT, {}, this.token);
    return updateCities;
  };

  public getDownload = async (id: string) => {
    const user = await new AuthClient().getUser();
    const token = user.tokenPayload?.access_token;
    try {
      let options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const res = await fetch(
        `${process.env.GATSBY_AUTH_API_ROOT_URL}/event-configs/${id}/refdata-active-file-download`,
        options
      );
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      return { url, size: blob.size, type: blob.type };
    } catch (err) {
      console.error(err);
    }
  };

  public getVisitorDownload = async (id: string) => {
    const user = await new AuthClient().getUser();
    const token = user.tokenPayload?.access_token;
    try {
      let options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const res = await fetch(
        `${process.env.GATSBY_AUTH_API_ROOT_URL}/event-configs/${id}/data-download`,
        options
      );
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      return { url, size: blob.size, type: blob.type };
    } catch (err) {
      throw err;
    }
  };
}

export default EventConfigsClient;
