import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import tw from 'twin.macro'
import { PaginationProps } from "./Pagination.d"

const Pagination: React.FC<PaginationProps> = ({numberOfItems, itemsPerPage, currentView, handleNext, handlePrev, handleJumpTo, ...remainingProps}) => {
    const pages = Array(Math.ceil(numberOfItems/itemsPerPage)).fill(0).map((_, index) => index + 1)
    if(pages.length < 2) return null
    
    return (
        <div css={tw`bg-white flex items-center justify-between font-medium`} {...remainingProps}>
            <div css={tw`flex-1 flex justify-between sm:hidden`}>
                <button
                    onClick={handlePrev}
                    css={tw`inline-flex items-center px-4 py-2 border border-gray-300 text-sm rounded-md text-gray-700 bg-white hover:bg-gray-50`}
                >
                    Previous
                </button>
                <button
                    onClick={ handleNext}
                css={tw`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm rounded-md text-gray-700 bg-white hover:bg-gray-50`}
                >
                    Next
                </button>
            </div>
            <div css={tw`hidden sm:inline-block`}>
                <nav css={tw`flex rounded-md shadow-sm -space-x-px text-sm`} aria-label="Pagination">
                    <button
                        onClick={handlePrev}
                        css={tw`inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50`}
                    >
                        <span css={tw`sr-only`}>Previous</span>
                        <ChevronLeftIcon css={tw`h-5 w-5`} aria-hidden="true" />
                    </button>
                    {pages?.map((value) => (
                        <button
                            onClick={() => handleJumpTo(value)}
                            css={[
                                tw`bg-white border-gray-300 text-gray-500 hover:bg-gray-50 inline-flex items-center px-4 py-2 border`,
                                currentView === value && tw`bg-primary-100 text-primary-600 hover:bg-primary-200`
                            ]}
                        >
                            {value.toString()}
                        </button>

                    ))
                    }
                    <button
                        onClick={handleNext}
                        css={tw`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50`}
                    >
                        <span css={tw`sr-only`}>Next</span>
                        <ChevronRightIcon css={tw`h-5 w-5`} aria-hidden="true" />
                    </button>
                </nav>
            </div>
        </div>
    )
}

export default Pagination
