import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";
import { Sponsor } from "./Events.d";

class SponsorClient extends APIBaseClient {
    constructor() {
        super()
        this.baseEndpoint = 'sponsors'
    }

    public getOne = async (id: string) => {
        const sponsor = await this.call(id, HTTP_METHOD.GET)
        return sponsor
    }

    public update = async (sponsor: Sponsor) => {
        const user = await new AuthClient().getUser()
        const token = user.tokenPayload?.access_token
        const res = await this.call(sponsor.sponsorID, HTTP_METHOD.PUT, sponsor, token)
        return res
    }
}

export default SponsorClient