import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";
import { Question } from "./Questionnaire.d";

class QuestionnaireHeaderClient extends APIBaseClient {
  constructor() {
    super();
    this.baseEndpoint = "questionnaire";
  }
  private loggedInUser = new AuthClient().getUser();
  private token = this.loggedInUser.tokenPayload?.access_token;

  public get = async () => {
    const res = await this.call("", HTTP_METHOD.GET, this.token);
    return res;
  };

  public update = async (header: string) => {
    const res = await this.call("", HTTP_METHOD.PUT, header, this.token);
    return res;
  };
}


export default QuestionnaireHeaderClient;