import { PencilAltIcon, TrashIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import ThemesClient from "../../../clients/Themes";
import { Theme } from "../../../clients/Themes.d";
import { NotificationsContext } from "../../../context/Notifications";
import { addNotification } from "../../../context/Notifications/actions";
import { Button } from "../../Button";
import Container from "../../Container/Container";
import { Link } from "../../Link/Link";
import PageHeading from "../../PageHeading/PageHeading";
import { Table, TBody, TD, TH, THead, TR } from "../../Table/TableElements";

const ThemesListPage = () => {
  const [data, setData] = useState(null);
  const [notifications, dispatch] = useContext(NotificationsContext)

  useEffect(() => {
    const getThemes = async () => {
      try {
        const themes = await new ThemesClient().get();
        themes.map(t => {
          t.isDefaultTheme = false;
          if (t.themeID === '4b024624-a25a-4abe-a74f-926d8502ccbc') {
            t.isDefaultTheme = true;
          }
        });
        setData(themes)
      } catch(err) { 
        dispatch(addNotification({title: "Error", description: err?.message, type: "error"}))
      }
    };

    getThemes();
  }, []);

  const deleteTheme = async (id: string) => {
    const themeToDelete = data.filter(
      (e: { themeID: string }) => e.themeID === id
    )[0];
    const answer = confirm(
      `Are you sure you want to delete ${themeToDelete.themeName}?`
    );
    if (answer) {
      try {
        const result = await new ThemesClient().delete(id);
        setData(data.filter((t: { themeID: string }) => t.themeID !== id));
        dispatch(
          addNotification({
            title: "Success",
            description: "The theme has been deleted.",
            type: "success",
          })
        );
      } catch {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occured.",
            type: "error",
          })
        );
      }
    }
  };

  return (
    <Container>
      <PageHeading
        buttons={[
          {
            label: "Add New",
            link: "/themes/new",
            variations: { primary: 1 },
          },
        ]}
      >
        Themes
      </PageHeading>
      <Table>
        <THead>
          <TR>
            <TH>Theme</TH>
            <TH>Edit</TH>
            <TH>Delete</TH>
          </TR>
        </THead>
        <TBody>
        {data &&
          data.map((theme: Theme) => {
            return (
              <TR key={theme.themeID}>
                <TD css={[tw`font-medium`]}>{theme.themeName}</TD>
                <TD>
                  <Link to={`/themes/${theme.themeID}`}>
                    <PencilAltIcon
                      css={[tw`w-5 text-primary-600`]}
                      name="Edit Theme"
                    />
                  </Link>
                </TD>
                <TD>
                  {!theme.isDefaultTheme && (
                    <Button
                      title="Delete"
                      onClick={() => deleteTheme(theme.themeID)}
                      css={[tw`shadow-none`]}
                    >
                      <TrashIcon css={[tw`w-5 text-primary-600`]} />
                    </Button>
                  )}
                </TD>
              </TR>
            )
          })}
        </TBody>
      </Table>
    </Container>
  );
};

export default ThemesListPage;
