/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { Switch } from "@headlessui/react";
import tw from "twin.macro";

const Toggle = ({ label, enabled, onChange }) => {
  return (
    <Switch.Group as="div" css={[tw`inline-flex items-center`]}>
      {label && (
        <Switch.Label as="span" css={[tw`mr-3`]}>
          <span css={[tw`text-xs font-medium text-gray-600`]}>{label}</span>
        </Switch.Label>
      )}
      <Switch
        checked={enabled}
        onChange={(e) => onChange(e)}
        css={[
          tw`bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`,
          enabled && tw`bg-primary-600`,
        ]}
      >
        <span css={[tw`sr-only`]}>{label}</span>
        <span
          aria-hidden="true"
          css={[
            tw`translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`,
            enabled && tw`translate-x-5`,
          ]}
        />
      </Switch>
    </Switch.Group>
  );
};
export default Toggle;
