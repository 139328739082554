import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { Loader } from "../Icons";
import { Layout } from "../Layout";

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true)
  }, [])
  return (
    <Layout>
      {isMount ? (
        <>
          <div tw="p-4 sm:px-6 md:px-0">
            <h1 css={[tw`text-2xl font-semibold text-gray-900`]}>404</h1>
          </div>
          <div tw="px-4 py-4 sm:px-6 md:px-0">Page not found</div>
        </>
      ) : (
        <div css={tw`py-6`}>
          <Loader css={tw`animate-spin mx-auto h-8 w-8`} />
        </div>
      )}
    </Layout>
  );
};

export default NotFoundPage;
