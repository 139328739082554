import tw, { TwStyle } from "twin.macro";
import React, { Fragment, useState } from "react";
import { Dialog } from "@headlessui/react";
import Transition from "../Transition/Transition";
import { XIcon } from "@heroicons/react/solid";

/**
 * HeadlessUI "Dialog (SlideOver)"
 * Customized for twin.macro + typescript
 * https://headlessui.dev/react/dialog
 */

type ContentProps = {
  title: string;
  content: React.ReactNode;
  closeSlideOver?: () => void;
  closeLabel: string;
  titleProps?: { as?: React.ElementType };
  descriptionProps?: { as?: React.ElementType };
};

type SlideOverProps = {
  children: React.ReactNode;
  trigger: React.ReactNode;
  dialogProps?: {
    open?: boolean;
    onClose?: () => void;
    tw?: string;
    initialFocus?: React.MutableRefObject<HTMLElement | null>;
    static?: boolean;
    unmount?: undefined;
  } & { as?: React.ElementType };
  contentProps: ContentProps;
  dialogOverlayProps?: { as?: React.ElementType };
  titleProps?: { as?: React.ElementType };
  descriptionProps?: { as?: React.ElementType };
  title: string;
};

type TriggerProps = { openSlideOver: () => void };

const SlideOver: React.FC<SlideOverProps> = ({
  children,
  trigger,
  dialogProps,
  contentProps,
  dialogOverlayProps,
  titleProps,
  descriptionProps,
  title,
  ...remainingProps
}) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeSlideOver() {
    setIsOpen(false);
  }

  function openSlideOver() {
    setIsOpen(true);
  }

  return (
    <Fragment>
      {React.cloneElement(trigger, { onClick: openSlideOver })}
      <Transition show={isOpen} as={Fragment}>
        <Dialog tw="relative z-10" onClose={closeSlideOver} {...dialogProps}>
          <div tw="fixed inset-0 overflow-hidden">
            <div tw="absolute inset-0 overflow-hidden">
              <div tw="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child {...overlayTransitionProps} as={Fragment}>
                  <Dialog.Overlay
                    tw="fixed inset-0 bg-black opacity-30"
                    {...dialogOverlayProps}
                  />
                </Transition.Child>
                <Transition.Child
                  {...contentTransitionProps}
                  as={Fragment}
                  css={[tw`pointer-events-auto w-screen max-w-5xl`]}
                  {...remainingProps}
                >
                  <div css={[tw`relative z-10 `]}>
                    <Fragment>
                      <div
                        css={[
                          tw`flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl`,
                        ]}
                      >
                        <div css={[tw`flex min-h-0 flex-1 flex-col`]}>
                          <div css={[tw`px-4 sm:px-6 bg-primary-600 py-6`]}>
                            <div css={[tw`flex items-start justify-between`]}>
                              <Dialog.Title
                                css={[tw`text-lg font-medium text-white`]}
                              >
                                {title}
                              </Dialog.Title>
                              <div css={[tw`ml-3 flex h-7 items-center`]}>
                                <button
                                  type="button"
                                  css={[
                                    tw`rounded-md text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-primary-100`,
                                  ]}
                                  onClick={closeSlideOver}
                                >
                                  <span css={[tw`sr-only`]}>Close panel</span>
                                  <XIcon
                                    css={[tw`h-6 w-6`]}
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            css={[
                              tw`relative flex-1 py-6 px-4 sm:px-6 overflow-y-scroll`,
                            ]}
                          >
                            {children}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

const contentTransitionProps = {
  enter: tw`transform transition ease-in-out duration-500 sm:duration-700`,
  enterFrom: tw`translate-x-full`,
  enterTo: tw`translate-x-0`,
  leave: tw`transform transition ease-in-out duration-500 sm:duration-700`,
  leaveFrom: tw`translate-x-0`,
  leaveTo: tw`translate-x-full`,
};

const overlayTransitionProps = {
  enter: tw`ease-out duration-300`,
  enterFrom: tw`opacity-0 `,
  enterTo: tw`opacity-30`,
  leave: tw`ease-in duration-200`,
  leaveFrom: tw`opacity-30 `,
  leaveTo: tw`opacity-0 `,
};

export default SlideOver;
