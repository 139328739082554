import { ChartBarIcon, TemplateIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import Container from "../../Container/Container";
import Select from "../../Form/Select";
import PageHeading from "../../PageHeading/PageHeading";
import Tabs from "../../Tabs/Tabs";
import StatisticsTab from "./Statistics";
import StatusPage from "./Status";
import datadogLogs from "../../../logger";
import EventsAdmin from "../../../clients/EventsAdmin";
import { Event } from "../../../clients/Events";

const DashboardPage = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [activeEvent, setActiveEvent] = useState<number | string>(undefined);
  const [activeTab, setActiveTab] = useState<string>(undefined);

  const tabs = {
    status: StatusPage,
    statistics: StatisticsTab,
  };

  useEffect(() => {
    const getEvents = async () => {
      const client = new EventsAdmin();
      const events = await client.get();
      setEvents(events);
      // datadogLogs.logger.info('Events loaded', { events });
    };
    getEvents();
    const currentEvent = localStorage.getItem("activeEventId");
    if (currentEvent != null) {
      setActiveEvent(currentEvent);
    } else {
      setActiveEvent(0);
    }
    const currentTab = localStorage.getItem("activeTab");
    const firstTab = Object.keys(tabs)[0];
    if (currentTab != null) {
      setActiveTab(currentTab);
    } else {
      setActiveTab(firstTab);
    }
  }, []);

  useEffect(() => {
    activeEvent && localStorage.setItem("activeEventId", activeEvent.toString());
  }, [activeEvent]);
  useEffect(() => {
    activeTab && localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const Component = activeTab && tabs[activeTab];

  return (
    <Container>
      <PageHeading>Dashboard</PageHeading>
      <div css={[tw`mb-8`]}>
        <Select onChange={(e) => setActiveEvent(e.target.value)} value={activeEvent} label="Event">
          {events && events.map((option: Event) => <option key={option.eventID} value={option.eventID}>{option.title}</option>)}
        </Select>
      </div>
      <Tabs
        tabs={[
          {
            name: "Status",
            icon: TemplateIcon,
            href: "status",
            current: activeTab === "status",
          },
          {
            name: "Statistics",
            icon: ChartBarIcon,
            href: "statistics",
            current: activeTab === "statistics",
          },
        ]}
        onChange={(activeTab) => setActiveTab(activeTab)}
      />
      {Component && <Component eventID={events[activeEvent]?.eventID || activeEvent} />}
      <div tw="px-4 py-4 sm:px-6 md:px-0"></div>
    </Container>
  );
};

export default DashboardPage;
