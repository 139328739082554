import React from "react";
import tw from "twin.macro";
import ControlledInput from "./ControlledInput";
import { InputProps } from "./Form";

const Input: React.FC<InputProps> = ({
  label,
  name,
  value,
  onChange,
  onMouseOver,
  disabled,
  readOnly,
  type,
  placeholder,
  step,
  min,
  max,
  style,
  maxLength,
  ...remainingProps
}) => {
  return (
    <div css={[tw`w-full`, disabled && tw`pointer-events-none opacity-50`]} {...remainingProps}>
      <label htmlFor={name} css={[tw`block text-sm font-medium text-gray-700`]}>
        {label}
      </label>
      <div css={[tw`mt-1`]}>
        <ControlledInput
          id={name}
          name={name}
          type={type ? type : "text"}
          autoComplete={name}
          required
          value={value}
          onChange={onChange}
          onMouseOver={onMouseOver}
          placeholder={placeholder}
          step={step}
          min={min}
          max={max}
          maxLength={maxLength}
          readOnly={readOnly}
          css={[
            tw`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm`,
          ]}
          style={style}
        />
      </div>
    </div>
  );
};

export default Input;
