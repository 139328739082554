import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";

class SponsorDetailClient extends APIBaseClient {
  constructor() {
    super();
    this.baseEndpoint = "event-configs";
  }

  public get = async (id: string) => {
    const sponsors = await this.call(`${id}/job-sponsors-detail`, HTTP_METHOD.GET);
    return sponsors;
  };
}

export default SponsorDetailClient;
