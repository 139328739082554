import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import KioskAnalyticsClient from "../../../clients/KioskAnalytics";
import { KioskAnalyticsResponse } from "../../../clients/KioskAnalytics.d";
import { dateToFormattedString } from "../../../helpers";
import DatePicker from "../../Form/DatePicker";
import RadioGroup from "../../Form/RadioGroup";
import { Table, TBody, TD, TFoot, TH, THead, TR } from "../../Table/TableElements";
import { DashboardTabProps } from "./Dashboard.d";

const StatisticsTab: React.FC<DashboardTabProps> = ({ eventID }) => {
  const [startDate, setStartDate] = useState<string>(null);
  const [endDate, setEndDate] = useState<string>(null);
  const [visitorType, setVisitorType] = useState<string>(null);
  const [data, setData] = useState<KioskAnalyticsResponse>(null);

  useEffect(() => {
    if (!event) return;
    if (startDate && endDate && visitorType) {
      const getData = async () => {
        const client = new KioskAnalyticsClient();
        const data = await client.get(eventID, startDate, endDate, visitorType);
        setData(data);
      };
      getData();
    }
    startDate && localStorage.setItem("startDate", startDate);
    endDate && localStorage.setItem("endDate", endDate);
    visitorType && localStorage.setItem("visitorType", visitorType);
  }, [eventID, startDate, endDate, visitorType]);

  useEffect(() => {
    const currentStartDate = localStorage.getItem("startDate");
    const currentEndDate = localStorage.getItem("endDate");
    const currentVisitorType = localStorage.getItem("visitorType");
    if (currentStartDate != null) {
      setStartDate(currentStartDate);
    } else {
      setStartDate(`${new Date().getFullYear()}-01-01`);
    }
    if (currentEndDate != null) {
      setEndDate(currentEndDate);
    } else {
      setEndDate(dateToFormattedString(new Date()));
    }
    if (currentVisitorType != null) {
      setVisitorType(currentVisitorType);
    } else {
      setVisitorType("Total");
    }
  }, []);

  return (
    <div css={[tw`py-4`]}>
      <div css={[tw`grid grid-cols-2 gap-4`]}>
        <div>
          <p css={[tw`font-bold mb-2 text-lg`]}>Date Range</p>
          <div css={[tw`flex flex-col md:flex-row`]}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              css={[tw`mb-4 md:mb-0`]}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <div css={[tw`flex flex-col`]}>
          <p css={[tw`font-bold mb-2 text-lg`]}>Registration Type</p>
          <RadioGroup
            options={[
              { value: "total", label: "Total" },
              { value: "registered", label: "Registered" },
              { value: "anonymous", label: "Anonymous" },
            ]}
            onChange={(value) => setVisitorType(value)}
            selected={visitorType}
            css={[tw`flex items-center flex-1`]}
          />
        </div>
      </div>
      <div tw="py-4">{data && <StatsTable data={data} />}</div>
    </div>
  );
};

const StatsTable: React.FC<{ data: KioskAnalyticsResponse }> = ({ data }) => {
  const headings = [
    "Badges Scanned/Total",
    "Total Visitors",
    "QR Codes Scanned",
    "AVG Dwell",
    "Completion Rate",
    "Scanned at Next Kiosk Rate",
  ];
  const rows: Array<any> = [];
  Object.keys(data).map((key) => {
    if (key.startsWith("station")) {
      const stationNumber = Number(key[7]);
      const valueType = key.substring(8);
      const row = rows[stationNumber];
      if (typeof row !== "object") {
        rows[stationNumber] = {
          stationNumber: stationNumber,
          Label: null,
          BadgesScanned: null,
          Visitors: null,
          CodesScanned: null,
          AvgDuration: null,
          CompletionRate: null,
          ContinuationRate: null,
        };
      }
      rows[stationNumber][valueType] = data[key];
    }
  });
  return (
    <Table>
      <THead>
        <TR>
          <TH></TH>
          {headings.map((heading) => (
            <TH css={[tw`text-center`]}>{heading}</TH>
          ))}
        </TR>
      </THead>
      <TBody>
        {rows.map((row) => (
          <TR>
            <TD>{row.Label}</TD>
            <TD css={[tw`text-center`]}>{row.BadgesScanned || 0}</TD>
            <TD css={[tw`text-center`]}>{row.Visitors || 0}</TD>
            <TD css={[tw`text-center`]}>{row.stationNumber === 4 ? row.CodesScanned || 0 : ""}</TD>
            <TD css={[tw`text-center`]}>{row.AvgDuration || 0}</TD>
            <TD css={[tw`text-center`]}>{row.CompletionRate || 0}%</TD>
            <TD css={[tw`text-center`]}>{row.ContinuationRate || 0}%</TD>
          </TR>
        ))}
      </TBody>
      <TFoot>
        <TR>
          <TD>Total</TD>
          <TD css={[tw`text-center`]}>{data.totalBadgesScanned || 0}</TD>
          <TD css={[tw`text-center`]}>{data.totalVisitors || 0}</TD>
          <TD></TD>
          <TD></TD>
          <TD></TD>
          <TD></TD>
        </TR>
      </TFoot>
    </Table>
  );
};

export default StatisticsTab;
