import React, { useState, useEffect, useContext } from "react";
import tw from "twin.macro";
import Container from "../../../Container/Container";
import { Input } from "../../../Form";
import Select from "../../../Form/Select";
import { Table, TBody, TD, TH, THead, TR } from "../../../Table/TableElements";
import { Question } from "../../../../clients/Questionnaire.d";
import { Button } from "../../../Button";
import { Pagination } from "../../../Pagination";
import usePagination from "../../../../hooks/usePagination";
import QuestionnaireClient from "../../../../clients/Questionnaire";
import { NotificationsContext } from "../../../../context/Notifications";
import { addNotification } from "../../../../context/Notifications/actions";
import QuestionnaireHeaderClient from "../../../../clients/QuestionnaireHeader";

const QuestionnaireTab: React.FC = ({ data, setData }) => {
  const { questions } = data.data;
  const [currentResults, setCurrentResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 7;
  const [showAddNew, setShowAddNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifications, dispatch] = useContext(NotificationsContext);
  const [header, setHeader] = useState(null);

  const { handleNext, handlePrev, handleJumpTo, getCurrentData } = usePagination(
    questions,
    resultsPerPage,
    currentPage,
    setCurrentPage
  );

  const updateField = (key: string, value: any, id: string) => {
    const index = questions.findIndex((q: Question) => q.questionID === id);
    const updatedQuestions = [...questions];
    updatedQuestions[index] = {
      ...questions[index],
      [key]: value,
    };
    const newData = {
      ...data,
      data: {
        ...data.data,
        questions: [...updatedQuestions],
      },
    };
    setData(newData);
  };

  const handleAddNew = async (question: Question) => {
    setLoading(true);
    try {
      const res = await new QuestionnaireClient().create(question);
      setLoading(false);
      dispatch(
        addNotification({
          title: "Success",
          description: "A new question has been created.",
          type: "success",
        })
      );
      setShowAddNew(false);
    } catch (err) {
      setLoading(false);
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occured.",
            type: "error",
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!currentResults) {
      setCurrentResults(questions?.slice(0, resultsPerPage));
    }
    const results = getCurrentData();
    setCurrentResults(results);
  }, [currentPage, data]);

  useEffect(() => {
    const getHeader = async () => {
      const header = await new QuestionnaireHeaderClient().get();
      setHeader(header.header);
    };
    getHeader();
  }, []);

  return (
    <Container>
      {!showAddNew ? (
        <>
          <div css={[tw`flex font-bold items-center mb-5`]}>
            <p>Header Statement</p>
            <Input
              css={[tw`w-60 ml-8`]}
              label=""
              name="header"
              value={header}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHeader(e.target.value);
                setData({
                  ...data,
                  data: {
                    ...data.data,
                    header: e.target.value,
                  },
                });
              }}
            />
          </div>
          <Table>
            <THead>
              <TR>
                <TH>Status</TH>
                <TH>Question</TH>
                <TH>Option 1</TH>
                <TH>Option 2</TH>
                <TH>Option 3</TH>
              </TR>
            </THead>
            <TBody>
              {currentResults?.map((question: Question) => (
                <TR>
                  <TD>
                    <Select
                      label=""
                      name="statusCode"
                      value={question.statusCode}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        updateField(e.target.name, e.target.value, question.questionID)
                      }>
                      <option>Active</option>
                      <option>Inactive</option>
                    </Select>
                  </TD>
                  <TD>
                    <Input
                      label=""
                      name="questionBody"
                      value={question.questionBody}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateField(e.target.name, e.target.value, question.questionID)
                      }
                    />
                  </TD>
                  <TD>
                    <Input
                      label=""
                      name="answer1"
                      value={question.answer1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateField(e.target.name, e.target.value, question.questionID)
                      }
                    />
                  </TD>
                  <TD>
                    <Input
                      label=""
                      name="answer2"
                      value={question.answer2}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateField(e.target.name, e.target.value, question.questionID)
                      }
                    />
                  </TD>
                  <TD>
                    <Input
                      label=""
                      name="answer3"
                      value={question.answer3}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateField(e.target.name, e.target.value, question.questionID)
                      }
                    />
                  </TD>
                </TR>
              ))}
            </TBody>
          </Table>
          <div
            css={tw`flex flex-wrap-reverse content-center mt-5 sm:flex-nowrap sm:justify-between`}>
            <Button primary css={tw`inline-block mt-4 sm:mt-0`} onClick={() => setShowAddNew(true)}>
              Add New
            </Button>
            <Pagination
              numberOfItems={questions?.length}
              itemsPerPage={resultsPerPage}
              currentView={currentPage}
              handleNext={handleNext}
              handlePrev={handlePrev}
              handleJumpTo={(page: number) => handleJumpTo(page)}
              css={tw`w-full sm:w-auto`}
            />
          </div>
        </>
      ) : (
        <AddNewView
          show={showAddNew}
          setShow={setShowAddNew}
          handleSave={handleAddNew}
          loading={loading}
        />
      )}
      {/* <pre>{JSON.stringify(questions, null, 2)}</pre> */}
    </Container>
  );
};

export const AddNewView = ({ show, setShow, handleSave, loading }) => {
  const [newQuestion, setNewQuestion] = useState({
    questionBody: "",
    statusCode: "Active",
    answer1: "",
    answer2: "",
    answer3: "",
  });

  return (
    <Container>
      <Table>
        <THead>
          <TR>
            <TH>Status</TH>
            <TH>Question</TH>
            <TH>Option 1</TH>
            <TH>Option 2</TH>
            <TH>Option 3</TH>
          </TR>
        </THead>
        <TBody>
          <TR>
            <TD css={[tw`min-w-min`]}>
              <Select
                label=""
                name="statusCode"
                value={newQuestion.statusCode}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setNewQuestion({ ...newQuestion, [e.target.name]: e.target.value })
                }>
                <option>Active</option>
                <option>Inactive</option>
              </Select>
            </TD>
            <TD css={[tw`w-72`]}>
              <Input
                label=""
                name="questionBody"
                value={newQuestion.questionBody}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewQuestion({ ...newQuestion, [e.target.name]: e.target.value })
                }
              />
            </TD>
            <TD>
              <Input
                label=""
                name="answer1"
                value={newQuestion.answer1}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewQuestion({ ...newQuestion, [e.target.name]: e.target.value })
                }
              />
            </TD>
            <TD>
              <Input
                label=""
                name="answer2"
                value={newQuestion.answer2}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewQuestion({ ...newQuestion, [e.target.name]: e.target.value })
                }
              />
            </TD>
            <TD>
              <Input
                label=""
                name="answer3"
                value={newQuestion.answer3}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewQuestion({ ...newQuestion, [e.target.name]: e.target.value })
                }
              />
            </TD>
          </TR>
          <TR>
            <TD css={tw`w-48 flex`}>
              <Button light onClick={() => setShow(false)}>
                Cancel
              </Button>
              <Button
                primary
                css={[tw`ml-5`]}
                onClick={() => handleSave(newQuestion)}
                loading={loading}>
                Save
              </Button>
            </TD>
          </TR>
        </TBody>
      </Table>
    </Container>
  );
};

export default QuestionnaireTab;
