import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import EventsAdmin from "../../../clients/EventsAdmin";
import Container from "../../Container/Container";
import { Link } from "../../Link/Link";
import PageHeading from "../../PageHeading/PageHeading";
import { Table, TBody, TD, TH, THead, TR } from "../../Table/TableElements";
import { AdminEvent } from "../../../clients/Events.d";
import EventConfigsClient from "../../../clients/EventConfigs";
import { Button } from "../../Button";
import {
  DownloadIcon,
  RefreshIcon,
  PencilAltIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import BuildClient from "../../../clients/Build";
import { NotificationsContext } from "../../../context/Notifications";
import { addNotification } from "../../../context/Notifications/actions";
import EventsClient from "../../../clients/Events";

const EventsListPage = () => {
  const [data, setData] = useState(null);
  const [notifications, dispatch] = useContext(NotificationsContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const getEvents = async () => {
      const client = new EventsAdmin();
      const data = await client.get();
      setData(data);
    };
    getEvents();
  }, []);

  const deleteEvent = async (id: string) => {
    const eventToDelete = data.filter(
      (e: { eventID: string }) => e.eventID === id
    )[0];
    const answer = confirm(
      `Are you sure you want to delete ${eventToDelete.title}?`
    );
    if (answer) {
      try {
        const result = await new EventsClient().delete(id);
        setData(data.filter((e: { eventID: string }) => e.eventID !== id));
        dispatch(
          addNotification({
            title: "Success",
            description: "The event has been deleted.",
            type: "success",
          })
        );
      } catch {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occured.",
            type: "error",
          })
        );
      }
    }
  };

  // const deleteEvents = async () => {
  //   if (isSubmitting) return;
  //   setIsSubmitting(true);

  //   const events = [...data].filter(e => !e.isDefaultEvent)
  //   console.log('deleteEvents', events)
  //   const results = events.map(async (event) => {
  //     try {
  //       const res = await new EventsClient().delete(event.eventID);
  //       dispatch(
  //         addNotification({
  //           title: "Success",
  //           description: "Event has been deleted.",
  //           type: "success",
  //         })
  //       );
  //       return res;
  //     } catch (err) {
  //       if (err?.message && err?.message != "Response not okay.") {
  //         dispatch(
  //           addNotification({
  //             title: "Error",
  //             description: err.message,
  //             type: "error",
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           addNotification({
  //             title: "Error",
  //             description: "An unexpected error has occured.",
  //             type: "error",
  //           })
  //         );
  //       }
  //       return err;
  //     }
  //   });
  //   console.log("results", results);
  //   setIsSubmitting(false);
  // };

  return (
    <Container>
      <PageHeading
        buttons={[
          {
            label: "Add New",
            link: "/events/new",
            variations: { primary: true },
          },
          // {
          //   label: "Delete All",
          //   variations: { primary: true },
          //   onClick: deleteEvents,
          //   disabled: isSubmitting
          // },
        ]}
      >
        Events
      </PageHeading>

      <Table>
        <THead>
          <TR>
            <TH>Event Name</TH>
            <TH>Status</TH>
            <TH>Created</TH>
            <TH>Active Version</TH>
            <TH>Organization</TH>
            <TH>Edit</TH>
            <TH css={[tw`text-xs`]}>Update Website</TH>
            <TH>Delete</TH>
          </TR>
        </THead>
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        <TBody>
          {data &&
            data.map((event: AdminEvent) => (
              <EventRow key={event.eventID} onDelete={deleteEvent} {...event} />
            ))}
        </TBody>
      </Table>
    </Container>
  );
};

const EventRow = ({
  title,
  eventID,
  creationDate,
  organizationID,
  activeEventConfigID,
  orgName,
  statusCode,
  activeVersion,
  isDefaultEvent,
  onDelete,
}: AdminEvent) => {
  const date = new Date(creationDate.replace("T", " ")).toLocaleDateString();
  const [notifications, dispatch] = useContext(NotificationsContext);
  const isWebsiteEvent = eventID === "a39a74b4-79f1-4e0f-92a5-1edb8240a3e7";

  const triggerBuild = async () => {
    try {
      await new BuildClient().get();
      dispatch(
        addNotification({
          title: "Success",
          description: "The website has been updated.",
          type: "success",
        })
      );
    } catch {
      dispatch(
        addNotification({
          title: "Error",
          description: "An unexpected error has occured.",
          type: "error",
        })
      );
    }
  };

  return (
    <TR css={isWebsiteEvent && tw`bg-gray-200`}>
      <TD>{title}</TD>
      <TD>{statusCode}</TD>
      <TD>{date}</TD>
      <TD>{activeVersion}</TD>
      <TD>
        <Link to={`/organizations/${organizationID}`}>{orgName}</Link>
      </TD>
      <TD>
        <Link to={`/events/${eventID}`}>
          <PencilAltIcon
            css={[tw`w-5 text-primary-600`]}
            name="Edit Event"
          />
        </Link>
      </TD>
      <TD>
        {isWebsiteEvent && (
          <Button
            title="Update Website"
            onClick={triggerBuild}
            css={[tw`shadow-none`]}
          >
            <RefreshIcon css={[tw`w-5 text-primary-600`]} />
          </Button>
        )}
      </TD>
      <TD>
        {!isDefaultEvent && (
          <Button
            title="Delete"
            onClick={() => onDelete(eventID)}
            css={[tw`shadow-none`]}
          >
            <TrashIcon css={[tw`w-5 text-primary-600`]} />
          </Button>
        )}
      </TD>
    </TR>
  );
};

export default EventsListPage;
