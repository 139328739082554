
import React from 'react'
  
const ProgressBar = ({bgcolor,progress,height}) => {
     
    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: 'whitesmoke',
        borderRadius: 4,
        marginBottom: 8
      }
      
      const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
       borderRadius: 4,
        textAlign: 'right',
        lineHeight: 'normal'
      }
      
      const progresstext = {
        padding: 8,
        color: 'white',
        fontWeight: 500
      }
        
    return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        {/* <span style={progresstext}>{`${progress}%`}</span> */}
      </div>
    </div>
    )
}
  
export default ProgressBar;