import {
  BadgeCheckIcon,
  BriefcaseIcon,
  ChatAlt2Icon,
  CogIcon,
  DeviceTabletIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState, useContext } from "react";
import tw from "twin.macro";
import AssetClient from "../../../../clients/Asset";
import EventConfigsClient from "../../../../clients/EventConfigs";
import EventsClient from "../../../../clients/Events";
import { Sponsor } from "../../../../clients/Events.d"
import SchoolClient from "../../../../clients/School";
import { SchoolResponse } from "../../../../clients/School.d";
import { SchoolLogo } from "../../../../clients/School.d";
import SponsorClient from "../../../../clients/Sponsor";
import { NotificationsContext } from "../../../../context/Notifications";
import { addNotification } from "../../../../context/Notifications/actions";
import Container from "../../../Container/Container";
import PageHeading from "../../../PageHeading/PageHeading";
import Tabs from "../../../Tabs/Tabs";
import { EventsEditPageProps } from "../Event.d";
import GeneralTab from "./GeneralTab";
import JobSponsorsTab from "./JobSponsorsTab";
import KnownBadgeMessageTab from "./KnownBadgeMessageTab";
import MessagingTab from "./MessagingTab";
import StationsTab from "./Stations";

const EventsEditPage: React.FC<EventsEditPageProps> = ({ eventID }) => {
  const [activeTab, setActiveTab] = useState("general");
  const [data, setData] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);
  const [notifications, dispatch] = useContext(NotificationsContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isWebsiteEvent = eventID === 'a39a74b4-79f1-4e0f-92a5-1edb8240a3e7';

  const client = new EventsClient();

  useEffect(() => {
    if (!eventID) return;

    const getEventConfigData = async () => {
      try {
        const data = await client.getActiveEventConfig(eventID);
        // console.log('getEventConfigData', data)
        setData(data);
      } catch (err) {
        dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
      }
    };

    const getEventDetails = async () => {
      try {
        const eventData = await client.getOne(eventID);
        setEventDetails(eventData);
      } catch (err) {
        dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
      }
    };

    getEventConfigData();
    getEventDetails();
  }, []);

  const updateFormValue = (key: string, value: string) => {
    const newData = {
      ...data,
      data: {
        ...data.data,
        [key]: value,
      },
    };
    setData(newData);
  };

  const updateSponsor = async (sponsor: Sponsor) => {
    try {
      return await new SponsorClient().update(sponsor);
    } catch (err) {
      throw err;
    }
  };

  const updateSchoolLogo = async (schoolLogo: SchoolLogo) => {
    try {
      return await new SchoolClient().updateLogo(schoolLogo);
    } catch (err) {
      throw err;
    }
  };

  const submit = async () => {
    try {
      if (isSubmitting) return;
      setIsSubmitting(true);

      if (eventDetails.isDefaultEvent) {
        dispatch(
          addNotification({
            title: "Error",
            description: "Cannot edit the default event",
            type: "error",
          })
        );
        setIsSubmitting(false);
        return;
      }

      // Updating Event
      const eventRes = new EventsClient().update(eventID, eventDetails);
      // Updating EventConfig
      const eventConfigID = data.eventConfigID;
      const eventConfigRes = new EventConfigsClient().update(eventConfigID, data);      
      await Promise.all([eventRes, eventConfigRes])
      // Updating Sponsors
      const sponsorsRes = data.data.sponsors.map((sponsor: Sponsor) => {
        updateSponsor(sponsor)
      })
      // Update Schools
      const schoolsRes = data.data.schools.map((school: SchoolResponse) => {
        const schoolLogo = {
          eventConfigID: school.eventConfigID,
          schoolID: school.schoolID,
          logo: school.logo,
        };
        updateSchoolLogo(schoolLogo);
      });
      await Promise.all(sponsorsRes)
      await Promise.all(schoolsRes)
      await new AssetClient().prune(eventConfigID, false);
      dispatch(addNotification({title: "Success", description: "Your information has been updated.", type: "success"}))
    } catch(err) {
      if(err?.message && err?.message != "Response not okay.") {
        dispatch(addNotification({title: "Error", description: err.message, type: "error"}))
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description: "An unexpected error has occured.",
            type: "error",
          })
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const tabStyles = (slug: string) => {
    return [tw`hidden`, activeTab === slug && tw`block`];
  };

  const tabs = [
    {
      name: "General",
      slug: "general",
      component: GeneralTab,
      icon: CogIcon,
    },
    // {
    //   name: "Messaging",
    //   slug: "messaging",
    //   component: MessagingTab,
    //   icon: ChatAlt2Icon,
    // },
    {
      name: "Stations",
      slug: "stations",
      component: StationsTab,
      icon: DeviceTabletIcon,
    },
    {
      name: "Job Sponsors",
      slug: "job-sponsors",
      component: JobSponsorsTab,
      icon: BriefcaseIcon,
    },
    {
      name: "Known Badge Message",
      slug: "known-badge-message",
      component: KnownBadgeMessageTab,
      icon: BadgeCheckIcon,
    },
  ];

  return (
    <Container>
      <PageHeading
        buttons={[
          { label: "Cancel", variations: { light: true }, link: `/events`, disabled: isSubmitting },
          { label: "Save", variations: { primary: true }, onClick: submit, disabled: isSubmitting },
        ]}>
        {/* Edit {data?.data?.generalSetup?.name} */}
        Edit {eventDetails?.title} {isWebsiteEvent && ' (Website Event)'}
      </PageHeading>
      <Tabs
        tabs={tabs.map((tab) => ({
          name: tab.name,
          href: tab.slug,
          current: activeTab === tab.slug,
          icon: tab.icon,
        }))}
        onChange={(activeTab) => setActiveTab(activeTab)}
      />
      {data &&
        tabs.map((tab) => {
          const Component = tab.component;
          return (
            <div css={tabStyles(tab.slug)}>
              <Component
                data={data}
                updateFormValue={updateFormValue}
                setData={setData}
                eventDetails={eventDetails}
                setEventDetails={setEventDetails}
              />
            </div>
          );
        })}
    </Container>
  );
};

export default EventsEditPage;
