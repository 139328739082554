import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import UserClient from "../../../clients/User";
import { NotificationsContext } from "../../../context/Notifications";
import { addNotification } from "../../../context/Notifications/actions";
import { User } from "../../../global";
import { Button } from "../../Button";
import Container from "../../Container/Container";
import { Input } from "../../Form";
import PageHeading from "../../PageHeading/PageHeading";
import { Table, THead, TR, TH, TD, TBody } from "../../Table/TableElements";
import { Link } from "../../Link/Link";
import { navigate } from "gatsby";

const UsersNewPage: React.FC = () => {
  const [notifications, dispatch] = useContext(NotificationsContext);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [user, setUser] = useState<User>({ email: '', password: '', firstName: '', lastName: '', role: 'Admin' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);

  const client = new UserClient();

  useEffect(() => {
    const getUsers = async () => {
      try {
        const data = await client.getStandards();
        setData(data);
        setFilteredData(data);
      } catch (err) {
        if (err?.message && err?.message != "Response not okay.") {
          dispatch(addNotification({ title: "Error", description: err.message, type: "error" }));
        } else {
          dispatch(
            addNotification({
              title: "Error",
              description: "An unexpected error has occurred.",
              type: "error",
            })
          );
        }
      }
    };

    getUsers();
  }, []);

  const updateSearchField = async (value: string) => {
    setSearch(value);
    const lowerValue = value.toLowerCase();
    const filtered = data.filter((d: User) => d.firstName.toLowerCase().includes(lowerValue) || d.lastName.toLowerCase().includes(lowerValue) || d.email.toLowerCase().includes(lowerValue));
    const ordered = filtered.sort((a: User, b: User) => a.lastName > b.lastName);
    setFilteredData(ordered);
  };

  const validateEmail = (email: string) => {
    return /^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/.test(email);
  };

  const validatePassword = (password: string, confirmation: string) => {
    return password === confirmation;
  };

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValid = validateEmail(search);
    if (isValid === false) {
      dispatch(
        addNotification({
          title: "Error",
          description: "Please use a valid email.",
          type: "error",
        })
      );
      return;
    }

    const isValidPassword = validatePassword(password, passwordConfirmation);
    if (isValidPassword === false) {
      dispatch(
        addNotification({
          title: "Error",
          description: "Please make sure passwords match.",
          type: "error",
        })
      );
      return;
    }

    try {
      user.email = search;
      user.password = password;
      const res = await client.createAdmin(user);
      dispatch(
        addNotification({
          title: "Success",
          description: "You created an admin user!",
          type: "success",
        })
      );
      navigate(`/users/${res?.user?.userID}`);
    } catch (err) {
      if (err?.message && err?.message != "Response not okay.") {
        dispatch(
          addNotification({
            title: "Error",
            description: err.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          addNotification({
            title: "Error",
            description:
              "Unable to create an account for this email. Please try again.",
            type: "error",
          })
        );
      }
    }
  };

  return (
    <Container>
      <PageHeading
        buttons={[
          {
            label: "Cancel",
            variations: { light: true },
            link: "/users",
          }
        ]}
      >
        Add Admin
      </PageHeading>

      <form css={tw`space-y-6 w-1/2`}>
      <Input
          label="Search or Add User"
          name="search"
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateSearchField(e.target.value)
          }
        />
        {filteredData && filteredData.length === 0 && <Button onClick={handleSubmitForm} primary loading={isSubmitting}>
          Create Admin Account
        </Button>}
      </form>

      <hr css={tw`my-6`} />

      {filteredData && filteredData.length === 0 ? <>
        <Input
          label="Password"
          name="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
        <Input
          label="Password Confirmation"
          name="passwordConfirmation"
          type="password"
          value={passwordConfirmation}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPasswordConfirmation(e.target.value)
          }
        />
      </> : <Table css={tw`my-6`}>
        <THead>
          <TR>
            <TH>First Name</TH>
            <TH>Last Name</TH>
            <TH>Role</TH>
            <TH>Email</TH>
            <TH>Status</TH>
            <TH></TH>
          </TR>
        </THead>
        {filteredData &&
          filteredData.map((user: User) => (
            <TR>
              <TD>{user.firstName}</TD>
              <TD>{user.lastName}</TD>
              {user.role === 'Admin' && <TD>Super Admin</TD>}
              {user.role === 'Event Admin' && <TD>Event Admin</TD>}
              {user.role === 'Standard' && <TD>Standard Visitor</TD>}
              <TD>
                <Link to={`mailto:${user.email}`}>{user.email}</Link>
              </TD>
              <TD>{user.emailStatus}</TD>
              <TD>
                <Link to={`/users/${user.userID}`}>Add</Link>
              </TD>
            </TR>
          ))}
      </Table>}
    </Container>
  );
};

export default UsersNewPage;
