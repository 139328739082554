import { HTTP_METHOD } from "../global.d";
import APIBaseClient from "./APIBase";
import AuthClient from "./Auth";

class TemplateClient extends APIBaseClient {
  constructor() {
    super();
    this.baseEndpoint = "admin";
  }
  private loggedInUser = new AuthClient().getUser();
  private token = this.loggedInUser.tokenPayload?.access_token;

  public getTemplates = async () => {
    const templates = await this.call(
      "events",
      HTTP_METHOD.GET,
      {},
      this.token
    );
    return templates;
  };
}

export default TemplateClient;
