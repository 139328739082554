export const useVisitorFieldsOptions = () => [
    // {
    //   label: "Age Group",
    //   propertyKey: "ageGroupOptions",
    // }, 
    {
      label: "Country",
      propertyKey: "countryOptions",
    }, 
    {
      label: "State",
      propertyKey: "stateOptions",
    }, 
    {
      label: "Race",
      propertyKey: "ethnicityOptions",
    }, 
    {
      label: "Gender",
      propertyKey: "genderOptions",
    }, 
]